import { CubiscanDimensionerDevice } from './cubiscan-dimensioner-device';
import { DimensioningOperation } from './dimensioning-operation';
import { IllegalArgumentError } from './error/illegal-argument-error';

export class CubiscanDimensioningOperation extends DimensioningOperation {
  private _cubiscanDevice: CubiscanDimensionerDevice;

  private _dimensionerLength: number;
  private _dimensionerWidth: number;
  private _dimensionerHeight: number;
  private _image1Base64data: string;
  private _image2Base64data: string;
  private _imageOverview: string;

  constructor(
    cubiscanDevice: CubiscanDimensionerDevice,
    proNumber: string,
    manualLength: number,
    manualWidth: number,
    manualHeight: number,
    aComment: string,
  ) {
    super(proNumber, manualLength, manualWidth, manualHeight, aComment);
    if (!cubiscanDevice) {
      throw new IllegalArgumentError('null cubiscanDevice');
    }
    this._cubiscanDevice = cubiscanDevice;
  }


  get cubiscanDevice(): CubiscanDimensionerDevice {
    return this._cubiscanDevice;
  }

  protected override toLogsSuffix(): string {
    return ' Device:' + this._cubiscanDevice.toLogs();
  }

  setScannedDimensions(aLength: number, aWidth: number, aHeight: number) {
    this._dimensionerLength = aLength;
    this._dimensionerWidth = aWidth;
    this._dimensionerHeight = aHeight;
  }


  get dimensionerLength(): number {
    return this._dimensionerLength;
  }

  get dimensionerWidth(): number {
    return this._dimensionerWidth;
  }

  get dimensionerHeight(): number {
    return this._dimensionerHeight;
  }

  get sicCode(): string {
    return this.cubiscanDevice.sicCode;
  }

  toDiffString(): string {
    const lLdiff: number = this._dimensionerLength - this.manualLength;
    const lWdiff: number = this._dimensionerWidth - this.manualWidth;
    const lHdiff: number = this._dimensionerHeight - this.manualHeight;

    let lLdiffS: string = this.addedPlusIfPositive(lLdiff);
    let lWdiffS: string = this.addedPlusIfPositive(lWdiff);
    let lHdiffS: string = this.addedPlusIfPositive(lHdiff);


    const result: string = `L:${lLdiffS} W:${lWdiffS} H:${lHdiffS}`;

    return result;
  }

  private addedPlusIfPositive(val: number): string {
    let result: string = val.toString();
    if (val > 0) {
      result = '+' + result;
    }
    return result;
  }


  get image1Base64data(): string {
    return this._image1Base64data;
  }

  set image1Base64data(value: string) {
    this._image1Base64data = value;
  }

  get image2Base64data(): string {
    return this._image2Base64data;
  }

  set image2Base64data(value: string) {
    this._image2Base64data = value;
  }

  setImages(base64DataImg1: string, base64DataImg2: string, base64DataImgOverview: string) {
    this._image1Base64data = base64DataImg1
    this._image2Base64data = base64DataImg2
    this._imageOverview = base64DataImgOverview
  }

  getOperationDurationMs(): number {
    return this._cubiscanDevice?.getOperationDurationMs()
  }


  get imageOverview(): string {
    return this._imageOverview;
  }

  set imageOverview(value: string) {
    this._imageOverview = value;
  }
}
