import { UiEntityProperty } from './ui-entity-property';
import { UiEntityPropertyTypeEnum } from '../enums/ui-entity-property-type-enum';
import { UiEntity } from './ui-entity';

/**
 * To generate those, paste all properties from a given type into a /tmp/variables file, then run a bash
 * script like
 */
// $ cat ~/services/xpo-settings/scripts/gen-mat-table-rows.sh
// #!/bin/bash
//
//
// echo "******* UI ENTITY  ******************************"
// variables=$(grep ';' /tmp/variables)
// #For table
// while IFS= read -r var;do
//   var_name=$(echo "$var"|cut -d':' -f1|sed 's/^ *//;s/ *$//')
// var_type=$(echo "$var"|cut -d':' -f2|sed 's/^ *//;s/ *$//'|sed 's/;//g')
// res="new UiEntityProperty('$var_name', '$var_type'),\n"
// printf "%b" "$res"
// done <<< "$variables"

export class UiEntities {
  // AUDIT INFOS
  static readonly AUDIT_INFO_PROPERTIES: UiEntityProperty[] = [
    new UiEntityProperty('auditInfo.createdById', UiEntityPropertyTypeEnum.STRING, { label: 'Created By' }),
    new UiEntityProperty('auditInfo.createdTimestamp', UiEntityPropertyTypeEnum.DATE, { label: 'Create Tmst' }),
    new UiEntityProperty('auditInfo.createByPgmId', UiEntityPropertyTypeEnum.STRING, { label: 'Created Program ID' }),
    new UiEntityProperty('auditInfo.updateById', UiEntityPropertyTypeEnum.STRING, { label: 'Last Updated By' }),
    new UiEntityProperty('auditInfo.updatedTimestamp', UiEntityPropertyTypeEnum.DATE, { label: 'Update Tmst' }),
    new UiEntityProperty('auditInfo.updateByPgmId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Last Updated Program ID',
    }),
  ];

  // COMMON
  static readonly COMMON_ENTITIES_FOR_LABELS: UiEntity = new UiEntity('commonUiEntity', [
    new UiEntityProperty('histActionCd', UiEntityPropertyTypeEnum.STRING, {
      label: 'History Action Code',
      columnTooltip: 'Action Codes: I=Inserted, D=Deleted, U=Updated',
    }),
    new UiEntityProperty('histCreatedDateTime', UiEntityPropertyTypeEnum.DATE, { label: 'History Creation Date' }),
    new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, { label: 'Correlation ID' }),
    new UiEntityProperty('proNbr', UiEntityPropertyTypeEnum.PRO_NUMBER, { label: 'PRO' }),
    new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator',
      matTableColumnWidth: 10,
    }),
    new UiEntityProperty('shipmentInstId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Shipment Inst ID',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, { label: 'SIC' }),
    new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, { label: 'Operator ID' }),
    ...UiEntities.AUDIT_INFO_PROPERTIES,
  ]);

  static NOT_FORMATTED_NUMBER_COLUMN_NAMES: string[] = [
    'shipmentInstId',
    'correlationId',
  ];


  // INS_DIMENSIONER_LOG
  static readonly INS_DIMENSIONER_LOG: UiEntity = new UiEntity('LogDetailHist', [
    new UiEntityProperty('dimensionerLogId', UiEntityPropertyTypeEnum.STRING, {
      label: 'ID',
      collapsedColumnIndex: 10,
      isVisibleInCollapsed: true,
    }),
    new UiEntityProperty('shpInstId', UiEntityPropertyTypeEnum.NUMBER, {
      label: '',
      collapsedColumnIndex: 210,
    }),
    new UiEntityProperty('proNbr', UiEntityPropertyTypeEnum.CUSTOM, {
      collapsedColumnIndex: 10,
      label: 'Pro Number',
      isVisibleInCollapsed: true,
    }),
    new UiEntityProperty('pkupDt', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
      label: '',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 200,
    }),
    new UiEntityProperty('dimensionerId', UiEntityPropertyTypeEnum.STRING, {
      label: '',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 190,
    }),
    new UiEntityProperty('dimTmst', UiEntityPropertyTypeEnum.DATE, {
      label: '', isVisibleInCollapsed: true,
      collapsedColumnIndex: 180,
    }),
    //diffs
    new UiEntityProperty('deltaL', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Length Delta',
      columnTooltip: UiEntities.getDeltaTooltip('Length'),
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 20,
    }),
    new UiEntityProperty('deltaW', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Width Delta',
      columnTooltip: UiEntities.getDeltaTooltip('Width'),
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 20,
    }),
    new UiEntityProperty('deltaH', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Height Delta',
      columnTooltip: UiEntities.getDeltaTooltip('Height'),
      isVisibleInCollapsed: true,
      matTableColumnWidth: 10,
      collapsedColumnIndex: 20,
    }),

    //manual
    new UiEntityProperty('lengthNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Manual Length',
      isVisibleInCollapsed: true,
      matTableColumnWidth: 10,
      collapsedColumnIndex: 30,
    }),
    new UiEntityProperty('widthNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Manual Width',
      isVisibleInCollapsed: true,
      matTableColumnWidth: 10,
      collapsedColumnIndex: 40,
    }),
    new UiEntityProperty('heightNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Manual Height',
      isVisibleInCollapsed: true,
      matTableColumnWidth: 10,
      collapsedColumnIndex: 50,
    }),
    new UiEntityProperty('statusCd', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('crteBy', UiEntityPropertyTypeEnum.STRING, { label: '', isVisibleInCollapsed: true }),
    new UiEntityProperty('crteTmst', UiEntityPropertyTypeEnum.DATE, { label: '', isVisibleInCollapsed: true }),
    new UiEntityProperty('crtePgmId', UiEntityPropertyTypeEnum.STRING, { label: '', isVisibleInCollapsed: true }),
    new UiEntityProperty('lstUpdtBy', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('lstUpdtTmst', UiEntityPropertyTypeEnum.DATE, { label: '' }),
    new UiEntityProperty('lstUpdtPgmId', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('recordVersionNbr', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('insEventId', UiEntityPropertyTypeEnum.NUMBER, { label: '', isVisibleInCollapsed: true }),
    new UiEntityProperty('measurementId', UiEntityPropertyTypeEnum.NUMBER, { label: '', isVisibleInCollapsed: true }),
    new UiEntityProperty('forkliftNbr', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('weightLbs', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('originalVolCft', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('correctedVolCft', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('originalDensityPsf', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('correctedDensityPsf', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('adjustedDensityPsf', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('originalNmfcClass', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('correctedNmfcClass', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('originalNmfcItemNbrTxt', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('correctedNmfcItemNbrTxt', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('exceptionCd', UiEntityPropertyTypeEnum.STRING, { label: '' }),
    new UiEntityProperty('exceptionDesc', UiEntityPropertyTypeEnum.STRING, {
      label: 'Comment',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 90,
    }),
    new UiEntityProperty('revenueOriginalAmt', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('revenuePreviewAmt', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('revenueCorrectedAmt', UiEntityPropertyTypeEnum.NUMBER, { label: '' }),
    new UiEntityProperty('corrReqInstId', UiEntityPropertyTypeEnum.NUMBER, { label: 'Operation duration (seconds)',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 100,
    }),
    new UiEntityProperty('forkliftOperatorId', UiEntityPropertyTypeEnum.STRING, {
      label: '',
      isVisibleInCollapsed: true,
    }),
    new UiEntityProperty('adjustedLengthNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Cubi Length',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 60,
    }),
    new UiEntityProperty('adjustedWidthNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Cubi Width',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 70,
    }),
    new UiEntityProperty('adjustedHeightNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Cubi Height',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 80,
    }),
  ]);


  private static getDeltaTooltip(val: string): string {
    return 'Delta between manual/cubiscan ' + val + '. If positive cubiscan dimension is n bigger than manual';
  }
}
