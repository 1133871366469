<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>Dimensioner</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button *ngFor="let navigationItem of navigationItems" [routerLink]="navigationItem.routerLink"
            routerLinkActive="xpo-selected">
      {{navigationItem.label}}</button>
  </xpo-header-navigation>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="root-container">
    <div class="root-container-main">
      <router-outlet></router-outlet>
    </div>
    <xpo-footer xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>
<xpo-app-notification></xpo-app-notification>
