import { Injectable } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {

  public static readonly DEFAULT_DURATION: number = 10_000;
  public static readonly SHORT_DURATION: number = 3_000;


  constructor(protected xpoSnackBar: XpoSnackBar) {
  }

  error(lError: any, aDuration?: number) {
    this.xpoSnackBar.open({
      message: lError,
      status: 'error',
      matConfig: {
        duration: this.getDuration(aDuration),
      },
    });
  }

  success(lVal: string, aDuration?: number) {
    this.xpoSnackBar.open({
      message: lVal,
      status: 'success',
      matConfig: {
        duration: this.getDuration(aDuration),
      },
    });
  }

  warn(lVal: string, aDuration?: number) {
    this.xpoSnackBar.open({
      message: lVal,
      status: 'warn',
      matConfig: {
        duration: this.getDuration(aDuration),
      },
    });
  }

  protected getDuration(aDuration: number): number {
    let result: number = aDuration;
    if (!result) {
      result = UserNotificationService.DEFAULT_DURATION;
    }
    return result;
  }

  public static toSnackbarString(srcError: any): string {
    let result: string = '';
    if (typeof srcError === 'function') {
      const calledSrcErrorRes = srcError.call(undefined);
      if (calledSrcErrorRes) {
        result = calledSrcErrorRes;
      }
    } else {
      if (srcError?.error?.code) {
        result += 'code: ' + srcError.error.code;
      }
      const lErrorFromMoreInfo: string = srcError?.error?.error?.moreInfo[0]?.message;
      if (lErrorFromMoreInfo) {
        result += '\nmsg: ' + lErrorFromMoreInfo;
      } else if (srcError?.message) {
        result += '\nmsg: ' + srcError?.message;
      }
    }
    if (result.length === 0) {
      result = JSON.stringify(srcError);
    }
    return result;
  }

}
