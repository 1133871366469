import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import {
  CONFIG_MANAGER_SERVICE_CONFIGURATION,
  ConfigManagerModule,
  ConfigManagerService,
} from '@xpo-ltl/config-manager';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataApiModule } from '@xpo-ltl/data-api';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { DimensionerSharedModule } from './dimensioner/shared/dimensioner-shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BASE_PATH as opsDimensionerApiEndpoint } from 'libs/libs/ltl-java-dimensioner-rest';
import { BASE_PATH as opsInspectionsApiEndpoint } from 'libs/libs/ltl-java-opsinspections-rest';
import {
  DimensionerDefaultErrorHandlerService,
} from './dimensioner/shared/lib/services/dimensioner-default-error-handler.service';
import { NgMaterialModule } from './dimensioner/shared/ng-material.module';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';
import { DimensionerDataService } from './dimensioner/shared/lib/services/dimensioner-data.service';
import {
  DimensionerAppConfigurationService,
} from './dimensioner/shared/lib/services/dimensioner-app-configuration.service';
import { OpsInspectionsApiWrapperService } from './dimensioner/shared/lib/services/ops-inspections-api-wrapper.service';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

export function buildApiUrl(configManagerService: ConfigManagerService, uri: string): string {
  let apiBase: string = configManagerService.getSetting('apiUri');

  if (!apiBase || apiBase.trim().length === 0) {
    throw new Error('apiBase for ' + uri + ' not found or blank');
  }

  if (apiBase.substr(-1) !== '/') {
    apiBase += '/';
  }

  return apiBase + (uri.charAt(0) === '/' ? uri.substr(1) : uri);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    ConfigManagerModule,
    DataApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    XpoShellModule,
    XpoFooterModule,
    XpoCardModule,
    XpoLtlAuthUiModule,
    AppRoutingModule,

    NgMaterialModule,

    DimensionerSharedModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    {
      provide: ErrorHandler,
      useClass: DimensionerDefaultErrorHandlerService,
    },
    {
      provide: opsDimensionerApiEndpoint,
      useFactory: (configManagerService: ConfigManagerService) => {
        return buildApiUrl(configManagerService, '/opsdimensioner/1.0');
      },
      deps: [ConfigManagerService],
    },
    {
      provide: opsInspectionsApiEndpoint,
      useFactory: (configManagerService: ConfigManagerService) => {
        return buildApiUrl(configManagerService, '/opsinspections/1.0');
      },
      deps: [ConfigManagerService],
    },
    DimensionerAppConfigurationService,
    DocumentManagementApiService,
    OpsInspectionsApiWrapperService,
    DimensionerDataService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
