import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { List } from 'immutable';
import { SelectOptionItem } from '../uiclasses/select-option-item';
import { CubiscanConstants } from '../classes/cubiscan-constants';
import { CubiscanDimensionerDevice } from '../classes/cubiscan-dimensioner-device';
import { IllegalArgumentError } from '../classes/error/illegal-argument-error';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  protected devicesListSelectOptionItemsBs$: BehaviorSubject<List<SelectOptionItem>> = new BehaviorSubject<List<SelectOptionItem>>(List());
  devicesListSelectOptionItems$: Observable<List<SelectOptionItem>> = this.devicesListSelectOptionItemsBs$.asObservable();

  protected devicesMap: Map<string, CubiscanDimensionerDevice>;

  constructor() {
    this.initialize();
  }

  protected initialize() {
    let lList: List<SelectOptionItem> = List();
    this.devicesMap = new Map();
    CubiscanConstants.DIMENSIONER_DEVICES.forEach((lDimensioner: CubiscanDimensionerDevice) => {
      const lSelectOptionItem: SelectOptionItem = lDimensioner.toSelectOptionItem();
      lList = lList.push(lSelectOptionItem);
      this.devicesMap.set(lDimensioner.serialNumber, lDimensioner);
    });

    this.devicesListSelectOptionItemsBs$.next(lList);
  }

  getDefaultSelectedDeviceValue(): string {
    //if you change that it wont change the default device on the view...
    return CubiscanConstants.DEVICE_GOODLETTSVILLE_1.serialNumber;
  }

  getDeviceFromSerial(lDeviceSerial: string): CubiscanDimensionerDevice {
    const result: CubiscanDimensionerDevice = this.devicesMap.get(lDeviceSerial);
    if (!result) {
      throw new IllegalArgumentError('given serial has not been found into map:' + lDeviceSerial);
    }
    return result;
  }
}
