export class SelectOptionItem {
  constructor(value: string, label: string, matTooltip?: string) {
    this.value = value;
    this.label = label;
    this.matTooltip = matTooltip;
  }

  value: string;
  label: string;
  matTooltip?: string;
}
