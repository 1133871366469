import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { UserNotificationService } from './user-notification.service';

/**
 * Dont use this, this is only as default error handler, error handling should be handled by ErrorHandlingService class
 */
@Injectable({
  providedIn: 'root'
})
export class DimensionerDefaultErrorHandlerService implements ErrorHandler {

  public static readonly DEFAULT_ERROR_DURATION: number = 10_000;

  constructor(private injector: Injector,
              protected xpoSnackBar: XpoSnackBar
  ) {}

  handleError(srcError: any): void {
    // const errorHandlingService: ErrorHandlingService = this.injector.get(ErrorHandlingService);
    console.error('Default error handler->handleError: src:', srcError);

    const lError: string = UserNotificationService.toSnackbarString(srcError)
    //already logged above
    this.xpoSnackBar.open({
      message: lError,
      status: 'error',
      matConfig: {
        duration: DimensionerDefaultErrorHandlerService.DEFAULT_ERROR_DURATION
      }
    });
  }
}
