import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { List } from 'immutable';
import { SelectOptionItem } from '../../lib/uiclasses/select-option-item';
import { DevicesService } from '../../lib/services/devices.service';

export class DimensionsComponentsBase {

  protected currentStatusStringBs$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentStatusString$: Observable<string> = this.currentStatusStringBs$.asObservable();


  filterFormGroup: FormGroup;
  devicesList$: Observable<List<SelectOptionItem>>;


  constructor(protected devicesService: DevicesService) {
    this.devicesList$ = this.devicesService.devicesListSelectOptionItems$;
  }

  protected setCurrentStatusString(val: string) {
    this.currentStatusStringBs$.next(val);
  }

}
