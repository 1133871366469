import { Component } from '@angular/core';
import {
  UiEntityTableCellComponentBase
} from '../../lib/ui-entity-mat-table/directives/ui-entity-table-cell-component-base';
import { ExtendedInsDimensionerLog } from '../../lib/ui-entity-mat-table/entities/extended-ins-dimensioner-log';
import { DmsApiWrapper } from '../../lib/services/dms-api-wrapper.service';
import { UserNotificationService } from '../../lib/services/user-notification.service';
import { DimensionerDmsDocument } from '../../lib/classes/api/dms/dimensioner-dms-document';
import { IllegalStateError } from '../../lib/classes/error/illegal-state-error';
import { MiscUtil } from '../../lib/ui-entity-mat-table/utils/misc-util';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { List } from 'immutable';
import { DmsDocument } from '@xpo-ltl/sdk-documentmanagement';
import { OpsInspectionsApiWrapperService } from '../../lib/services/ops-inspections-api-wrapper.service';
import { ProNumberHelper } from '../../lib/helpers/pro-number-helper';
import {
  ExtendedDocumentDto,
  ExtendedDocumentSearchDto,
} from '../../../../../../../../libs/libs/ltl-java-opsinspections-rest';
import { OpsInspectionExtendedDs } from '../../lib/classes/api/inspection-dimensions/ops-inspection-extended-ds';
import { DateHelper } from '../../lib/helpers/date-helper';

@Component({
  selector: 'app-dimensions-table-custom',
  templateUrl: './dimensions-table-custom.component.html',
  styleUrls: ['./dimensions-table-custom.component.scss']
})
export class DimensionsTableCustomComponent extends UiEntityTableCellComponentBase<ExtendedInsDimensionerLog> {
  dmsAssociatedDocumentsBs$: BehaviorSubject<List<OpsInspectionExtendedDs>> = new BehaviorSubject<List<OpsInspectionExtendedDs>>(List());
  dmsAssociatedDocuments$: Observable<List<OpsInspectionExtendedDs>> = this.dmsAssociatedDocumentsBs$.asObservable()


  constructor(private dmsApiWrapper: DmsApiWrapper,
              protected userNotificationService: UserNotificationService,
              private _sanitizer: DomSanitizer,
              protected opsInspectionsApiWrapperService: OpsInspectionsApiWrapperService
              ) {
    super();
  }

  onProNbrClicked(aUiEntityData: ExtendedInsDimensionerLog) {
    console.log('onProNbrClicked->', aUiEntityData)
    this.downloadImpl(aUiEntityData)
  }

  private downloadImpl(uiEntityData: ExtendedInsDimensionerLog) {
    let lProNbr: string = uiEntityData.proNbr;
    let lCreatedTimestamp: Date = new Date();//todo pg: tricky change me
    const lNineDigitPro: string = ProNumberHelper.getNineDigitString(uiEntityData.proNbr)
    const lCrteTmst = DateHelper.fromApiDateTime(uiEntityData.crteTmst)
    this.opsInspectionsApiWrapperService.findDocuments$(lNineDigitPro, lCrteTmst, 3)
      .subscribe((respList: List<ExtendedDocumentDto>) => {
        if (!respList?.isEmpty()) {
          let lList = this.dmsAssociatedDocumentsBs$.value;
          respList.forEach(
            (extendedDocumentSearchDto: ExtendedDocumentDto) =>{
              const lOpsInspectionExtendedDs: OpsInspectionExtendedDs = OpsInspectionExtendedDs.fromExtendedDocumentDto(extendedDocumentSearchDto)
              lOpsInspectionExtendedDs.setSanitizedBase64Data(this._sanitizer)
              lList = lList.push(lOpsInspectionExtendedDs)
            }
          )
          this.dmsAssociatedDocumentsBs$.next(lList)
        } else {
          this.userNotificationService.error('No document info available');
        }
      });

    // this.dmsApiWrapper
    //   .getDmsDocument$(lProNbr, lCreatedTimestamp)
    //   .subscribe((dmsDocument: DimensionerDmsDocument) => {
    //     if (dmsDocument) {
    //       dmsDocument.setSanitizedBase64Data(this._sanitizer)
    //       let lList = this.dmsAssociatedDocumentsBs$.value;
    //       lList = lList.push(dmsDocument)
    //       this.dmsAssociatedDocumentsBs$.next(lList)
    //       // const lData = dmsDocument.getDecodedData()
    //       // const file: Blob = new Blob([lData], {
    //       //   type: dmsDocument.getMimeType() + ';base64',
    //       // });
    //       // const link: HTMLAnchorElement = document.createElement('a');
    //       // link.href = window.URL.createObjectURL(file);
    //       // link.download = dmsDocument.getFileName();
    //       // link.click();
    //     } else {
    //       this.userNotificationService.error('No document info available');
    //     }
    //   });
  }

  onImageClicked(aEvent: MouseEvent) {
    MiscUtil.toggleImageHeight(aEvent)
  }
}
