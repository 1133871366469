<div class="overall">
  <h1>Dimensioner logs {{ (tableDataList$|async).size }}</h1>
  <div class="form-items">
    <form class="tab-body tab-filters" [formGroup]="filterFormGroup" autocomplete="off">
      <mat-form-field floatLabel="always">
        <mat-label>Selected device</mat-label>
        <mat-select
          placeholder="Device"
          xpoSelect
          [compareWith]="compareDevicesSelectFunction"
          [formControlName]="formNames.DEVICE_SELECT"
          (selectionchange)="onSelectDeviceChanged($event)"
        >
          <mat-option *ngFor="let item of (devicesList$|async)" [value]="item.value" [matTooltip]="item.matTooltip" matTooltipPosition="right">
            {{ item.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div class="status">
      <strong>Status:</strong> {{currentStatusString$|async}}
    </div>
    <xpo-button-group>
      <button type="button" class="btn-display" (click)="onRefreshButtonClicked()">
        <mat-icon>refresh</mat-icon>
      </button>
    </xpo-button-group>
  </div>
  <div class="entities-table">
    <app-dimensions-table
      [tableDataAsList]="tableDataList$ | async"
    ></app-dimensions-table>
  </div>
</div>
