import { SafeResourceUrl } from '@angular/platform-browser';

export class LogItem {
  text: string;
  base64Src1: SafeResourceUrl | undefined;
  base64Src2: SafeResourceUrl | undefined;
  base64SrcOvv: SafeResourceUrl | undefined;
  createDate: Date;


  constructor(text: string, aBase64Src1?: SafeResourceUrl, aBase64Src2?: SafeResourceUrl, aBase64SrcOvv?: SafeResourceUrl) {
    this.createDate = new Date();
    this.text = text;
    this.base64Src1 = aBase64Src1;
    this.base64Src2 = aBase64Src2;
    this.base64SrcOvv = aBase64SrcOvv;
  }

  hasImage(): boolean {
    return !!this.base64Src1 || !!this.base64Src2 || !!this.base64SrcOvv;
  }


}
