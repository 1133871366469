import { SelectOptionItem } from '../uiclasses/select-option-item';
import { CubiscanConstants } from './cubiscan-constants';
import { IllegalArgumentError } from './error/illegal-argument-error';
import {
  ExtendedCubiscanDeviceScanningResponseDto,
} from './api/cubiscan/extended-cubiscan-device-scanning-response-dto';
import { CubiscanDeviceApiService } from '../services/cubiscan-device-api.service';
import { BehaviorSubject, catchError, delay, filter, Observable, of, repeat, switchMap, take, timeout } from 'rxjs';
import { List } from 'immutable';
import { DateHelper } from '../helpers/date-helper';
import { HttpErrorResponse } from '@angular/common/http';

export class CubiscanDimensionerDevice {

  serialNumber: string;
  macAddress: string;
  dnsName: string;
  restApiPort: string;
  location: string;
  sicCode: string;

  protected eventsContentBs$: BehaviorSubject<List<string>> = new BehaviorSubject<List<string>>(List());
  eventsContent$: Observable<List<string>> = this.eventsContentBs$.asObservable();

  //
  static readonly GET_DIMENSIONS_TIMEOUT_MS: number = 20_000;
  static readonly GET_DIMENSIONS_REPEAT_COUNT: number = 15;//max times polling
  static readonly GET_DIMENSIONS_REPEAT_DELAY_MS: number = 2_000;//between pollings
  static readonly GET_DIMENSIONS_READ_DATA_DELAY_MS: number = 1_500;//between start dimensioning and polling
  protected getDimensionsStartDate: Date;
  private getDimensionsEndDate: Date;


  constructor(serialNumber: string, location: string, macAddress: string, dnsName: string, restApiPort: string, aSicCode: string) {
    this.serialNumber = serialNumber;
    this.macAddress = macAddress;
    this.dnsName = dnsName;
    this.restApiPort = restApiPort;
    this.location = location;
    this.sicCode = aSicCode;
  }

  static fromSerial(aSerial: string): CubiscanDimensionerDevice {
    if (!aSerial || aSerial.length === 0) {
      throw new IllegalArgumentError('null aSerial');
    }
    let result: CubiscanDimensionerDevice;
    for (let i = 0; i < CubiscanConstants.DIMENSIONER_DEVICES.length; i++) {
      const lDevice = CubiscanConstants.DIMENSIONER_DEVICES[i];
      if (lDevice.serialNumber === aSerial) {
        result = lDevice;
        break;
      }
    }
    if (!result) {
      throw new Error('device not found from serial:' + aSerial);
    }
    return result;
  }

  toSelectOptionItem(): SelectOptionItem {
    const lValue: string = this.serialNumber;
    const lLabel: string = this.serialNumber;
    const lTooltip: string =
      this.serialNumber + ' => ' + ' dns=' + this.dnsName + ':' + this.restApiPort + ' mac:' + this.macAddress;
    const result = new SelectOptionItem(lValue, lLabel, lTooltip);
    return result;
  }

  toLogs(): string {
    return ' serial:' + this.serialNumber;
  }

  getDimensions$(aCubiscanDeviceApiService: CubiscanDeviceApiService,
                 aCubiscanDevice: CubiscanDimensionerDevice,
                 aProNumber: string,
                 isSimulatorTargetted: boolean): Observable<ExtendedCubiscanDeviceScanningResponseDto> {
    this.getDimensionsStartDate = new Date();
    this.getDimensionsEndDate = undefined;
    return aCubiscanDeviceApiService.startDimensioning$(aCubiscanDevice, aProNumber, isSimulatorTargetted).pipe(
      delay(CubiscanDimensionerDevice.GET_DIMENSIONS_READ_DATA_DELAY_MS),
      take(1),
      switchMap((startedDimensioningResponse: ExtendedCubiscanDeviceScanningResponseDto) => {
        this.startDimensioningImplSwitchmap(startedDimensioningResponse);
        return this.afterStartDimensioning$(aCubiscanDeviceApiService, aCubiscanDevice, aProNumber, isSimulatorTargetted);
      }),
      catchError((error: Error) => {
        console.error('getDimensions$ error->', error);
        if (error instanceof HttpErrorResponse &&
          error?.error?.moreInfo &&
          error?.error?.moreInfo[0].message?.contains('Measuring in course')) {
          throw error;
        } else {
          throw error;
        }
      }),
    );
  }

  startDimensioning$(aCubiscanDeviceApiService: CubiscanDeviceApiService,
                     aCubiscanDevice: CubiscanDimensionerDevice,
                     aProNumber: string,
                     isSimulatorTargetted: boolean): Observable<ExtendedCubiscanDeviceScanningResponseDto> {
    this.getDimensionsStartDate = new Date();
    this.getDimensionsEndDate = undefined;
    return aCubiscanDeviceApiService.startDimensioning$(aCubiscanDevice, aProNumber, isSimulatorTargetted);
  }

  //todo pg: should be protected
  afterStartDimensioning$(aCubiscanDeviceApiService: CubiscanDeviceApiService,
                          aCubiscanDevice: CubiscanDimensionerDevice,
                          aProNumber: string,
                          isSimulatorTargetted: boolean): Observable<ExtendedCubiscanDeviceScanningResponseDto> {
    console.log('afterStartDimensioning$->');
    let lRetryIndex: number = 0;
    return aCubiscanDeviceApiService.getDimensions$(aCubiscanDevice, aProNumber, isSimulatorTargetted).pipe(
      timeout(CubiscanDimensionerDevice.GET_DIMENSIONS_TIMEOUT_MS),
      repeat({
        delay: CubiscanDimensionerDevice.GET_DIMENSIONS_REPEAT_DELAY_MS,
        count: CubiscanDimensionerDevice.GET_DIMENSIONS_REPEAT_COUNT,
      }),
      filter((lResp: ExtendedCubiscanDeviceScanningResponseDto) => {
        lRetryIndex++;

        let result: boolean = false;
        if (lResp) {
          result = lResp.isValid();
          console.log('afterStartDimensioning$ loop for device(' + this.serialNumber + ') status:(' +
            lResp.getStatusFromMeasurementOrUndefined() + ') valid:' + result, lResp);
          if (lResp.hasError()) {
            throw new Error(lResp.getErrorMessageForHasError());
          }
          this.getDimensionsEndDate = new Date()
          var duration = this.getDimensionsEndDate.valueOf() - this.getDimensionsStartDate.valueOf();
          const lMsg: string = 'starting getting dimensions, valid response:' + result + ` retrying ${lRetryIndex}/${CubiscanDimensionerDevice.GET_DIMENSIONS_REPEAT_COUNT}...`;//'dimensioning at:' + DateHelper.getHHMMSS(this.startedDimensioningDate) + ' validResp:' + result;
          const lList: List<string> = this.eventsContentBs$.value.push(lMsg);
          this.eventsContentBs$.next(lList);
        } else {
          console.error('afterStartDimensioning$->lresp is null');
        }
        if (lRetryIndex >= CubiscanDimensionerDevice.GET_DIMENSIONS_REPEAT_COUNT) {
          throw new Error('retries for getDimensioner without being valid reached after ' + lRetryIndex + ' times');
        }
        return result;
      }),
      take(1),
      switchMap(
        (aExtendedRespDto: any) => {
          //should be valid here
          console.log('getDimensions$ subscribe result:' + this.serialNumber, aExtendedRespDto);

          const lMsg: string = 'Dimensioning at:' + DateHelper.getHHMMSS(this.getDimensionsStartDate)
            + ' finished:' + aExtendedRespDto.toDimensionsString()
            + ' took: ' + DateHelper.passedTimeStringTillNowHmS(this.getDimensionsStartDate);
          const lList: List<string> = this.eventsContentBs$.value.push(lMsg);
          this.eventsContentBs$.next(lList);

          return of(aExtendedRespDto);
        },
      ),
      // takeUntil(this.validDimensionsFound),
      // retry(CubiscanDevice.GET_DIMENSIONS_RETRY_COUNT),
    );
  }

  getApiBasePath(): string {
    return 'http://' + this.dnsName + ':' + this.restApiPort;
  }

  private startDimensioningImplSwitchmap(startedDimensioningResponse: ExtendedCubiscanDeviceScanningResponseDto) {
    console.log('startDimensioning$->', startedDimensioningResponse);
    const lMsg: string = 'started dimensioning at:' + DateHelper.getHHMMSS(this.getDimensionsStartDate);
    const lList: List<string> = this.eventsContentBs$.value.push(lMsg);
    this.eventsContentBs$.next(lList);
  }

  getOperationDurationMs(): number|undefined {
    let result: number = undefined
    if (this.getDimensionsStartDate && this.getDimensionsEndDate) {
      result = this.getDimensionsEndDate.valueOf() - this.getDimensionsStartDate.valueOf();
    }
    return result;
  }
}
