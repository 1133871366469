import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ExtendedInsDimensionerLog } from '../../lib/ui-entity-mat-table/entities/extended-ins-dimensioner-log';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { List } from 'immutable';
import { DimensionerApiWrapperService } from '../../lib/services/dimensioner-api-wrapper.service';
import { DimsCubiscanFormGroupItem } from '../../lib/enums/dims-cubiscan-form-group-item.enum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectOptionItem } from '../../lib/uiclasses/select-option-item';
import { ApiErrorHandlerService } from '../../lib/services/api-error-handler.service';
import { DevicesService } from '../../lib/services/devices.service';
import { IllegalStateError } from '../../lib/classes/error/illegal-state-error';
import { DimensionsComponentsBase } from '../base/dimensions-components-base';

@Component({
  selector: 'app-dimensions-view',
  templateUrl: './dimensions-view.component.html',
  styleUrls: ['./dimensions-view.component.scss',
    '../../lib/ui-entity-mat-table/components/ui-entity-mat-table/ui-entity-mat-table.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimensionsViewComponent extends DimensionsComponentsBase implements OnInit {
  protected tableDataListBs$: BehaviorSubject<List<ExtendedInsDimensionerLog>> = new BehaviorSubject<List<ExtendedInsDimensionerLog>>(List());
  tableDataList$: Observable<List<ExtendedInsDimensionerLog>> = this.tableDataListBs$.asObservable();

  //UI


  constructor(
    private fb: FormBuilder,
    private dimensionerApiWrapperService: DimensionerApiWrapperService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    protected override devicesService: DevicesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(devicesService);
  }

  ngOnInit(): void {
    this.filterFormGroup = this.fb.group({
      [DimsCubiscanFormGroupItem.DEVICE_SELECT]: this.fb.control(this.devicesService.getDefaultSelectedDeviceValue()),
    });
    // this.setTableDataList();
  }


  protected readonly formNames = DimsCubiscanFormGroupItem;

  compareDevicesSelectFunction(o1: any, o2: any) {
    return o1.name === o2.name && o1.id === o2.id;
  }

  onRefreshButtonClicked() {
    this.setTableDataList();
  }

  private setTableDataList() {
    const lSelectedDeviceSerialNumber: string = this.getSelectedDeviceSerialNumber();

    if (!lSelectedDeviceSerialNumber) {
      throw new IllegalStateError('selected device is invalid');
    } else {
      const lDimensionerId: string = lSelectedDeviceSerialNumber;
      if (!lDimensionerId || lDimensionerId.length === 0) {
        console.error('setTableDataList->lSelectedDeviceSerialNumber:', lSelectedDeviceSerialNumber, lDimensionerId);
        throw new IllegalStateError('dimensionerId not available');
      }
      this.setCurrentStatusString('Listing dimensions for selected device');
      this.dimensionerApiWrapperService.listDimensions$(lDimensionerId).pipe(catchError((err) => {
        this.apiErrorHandlerService.handleError(err);
        alert('List dimensions error, see console');
        console.error('listDimensions error:', err);
        return of(err);
      })).subscribe((lList: List<ExtendedInsDimensionerLog>) => {
        this.setCurrentStatusString('received ' + lList.size + ' items');
        this.tableDataListBs$.next(lList);
        // this.changeDetectorRef.markForCheck()
        console.log('listDimensions received:', lList);
      });
    }
  }

  private getSelectedDeviceSerialNumber(): string {
    let result: string = this.filterFormGroup.get(DimsCubiscanFormGroupItem.DEVICE_SELECT).value;
    return result;
  }

  onSelectDeviceChanged($event: Event) {
    this.setTableDataList();
  }
}
