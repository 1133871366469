import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, take } from 'rxjs';
import { CubiscanDimensionerDevice } from '../classes/cubiscan-dimensioner-device';
import { NoXpoApiServiceBase } from './no-xpo-api-service-base';
import { IllegalArgumentError } from '../classes/error/illegal-argument-error';
import { CubiscanGetDimensionsRequest } from '../classes/api/cubiscan/cubiscan-get-dimensions-request';
import {
  CubiscanDeviceScanningResponseDto,
  CubiscanDimensionerMockControllerService,
} from '../../../../../../../../libs/libs/ltl-java-dimensioner-rest';
import {
  ExtendedCubiscanDimensionerCsMeasureRequestBodyModel,
} from '../classes/api/cubiscan/extended-cubiscan-dimensioner-cs-measure-request-body-model';
import {
  ExtendedCubiscanDeviceScanningResponseDto,
} from '../classes/api/cubiscan/extended-cubiscan-device-scanning-response-dto';
import { DimensionerAppConfigurationService } from './dimensioner-app-configuration.service';

/**
 * Docs on sharepoint about spec:
 *  https://xpologistics.sharepoint.com/:b:/s/150/projects/EdgeInspections3.0/ERZncXzjT81HogR-IzTqAhEBCG1LiVR9oLczIAWOS9DT_g?e=3S02Yl
 */
@Injectable({
  providedIn: 'root',
})
export class CubiscanDeviceApiService extends NoXpoApiServiceBase {

  protected isLocalOnly: boolean = true;//if true sending requests to dimensionerApi otherwise to cubiscan dimensioner device api
  protected constructorBasePath: string;
  protected plainHttpClient: HttpClient;

  constructor(protected httpClient: HttpClient,
              protected cubiscanDimensionerMockControllerService: CubiscanDimensionerMockControllerService,
              protected dimensionerAppConfigurationService: DimensionerAppConfigurationService,
              handler: HttpBackend) {
    super();
    this.plainHttpClient = new HttpClient(handler);
    this.constructorBasePath = this.cubiscanDimensionerMockControllerService.configuration.basePath;
    this.setBasePathIfLocal(this.cubiscanDimensionerMockControllerService, this.dimensionerAppConfigurationService.isDimensionerDeviceApiAgainstLocalhost());
  }


  startDimensioning$(aCubiscanDevice: CubiscanDimensionerDevice,
                     aProNbrTxt: string,
                     isSimulatorTargetted: boolean): Observable<ExtendedCubiscanDeviceScanningResponseDto> {

    let apiResult$: Observable<CubiscanDeviceScanningResponseDto>;
    if (!aCubiscanDevice) {
      throw new IllegalArgumentError('null aCubiscanDevice');
    } else if (!aProNbrTxt || aProNbrTxt.length === 0) {
      throw new IllegalArgumentError('invalid pro');
    } else {
      let lProNbrTxt: string = aProNbrTxt;
      let lSerial: string = aCubiscanDevice.serialNumber;
      if (!lSerial || lSerial.length === 0) {
        throw new IllegalArgumentError('invalid serial for device');
      }
      this.setBasePath(aCubiscanDevice, isSimulatorTargetted);
      if (isSimulatorTargetted) {
        // @ts-ignore
        let lRequest: ExtendedCubiscanDimensionerCsMeasureRequestBodyModel = {
          barcode1: lProNbrTxt,
          cubiscanSerial: lSerial,
          Barcode1: lProNbrTxt,
        };
        apiResult$ = this.cubiscanDimensionerMockControllerService.csMeasure(lRequest);
      } else {
        let lRequest: ExtendedCubiscanDimensionerCsMeasureRequestBodyModel = {
          barcode1: lProNbrTxt,
          cubiscanSerial: lSerial,
          Barcode1: lProNbrTxt,
        };
        // apiResult$ = this.cubiscanDimensionerMockControllerService.csMeasure(lRequest);
        apiResult$ = this.csMeasure(aCubiscanDevice, aProNbrTxt);
      }
      // const result$: Observable<ExtendedCubiscanDeviceScanningResponseDto> = apiResult$.pipe(
      //   map((apiResult: any) => {
      //     console.debug('startDimensioning:', apiResult)
      //     const lResult: ExtendedCubiscanDeviceScanningResponseDto = ExtendedCubiscanDeviceScanningResponseDto.fromResponseDto(apiResult);
      //     return lResult;
      //   }));

      const result$: Observable<ExtendedCubiscanDeviceScanningResponseDto> = apiResult$.pipe(
        map((apiResult: any) => {
          console.debug('startDimensioning:', apiResult);
          const lResult: ExtendedCubiscanDeviceScanningResponseDto = apiResult;
          return lResult;
        }));
      return result$;
    }
  }

  getDimensions$(aCubiscanDevice: CubiscanDimensionerDevice,
                 aProNbrTxt: string,
                 isSimulatorTargetted: boolean): Observable<ExtendedCubiscanDeviceScanningResponseDto> {
    console.debug('getDimensions$ start->');
    let apiResult$: Observable<CubiscanDeviceScanningResponseDto>;
    if (!aCubiscanDevice) {
      throw new IllegalArgumentError('null aCubiscanDevice');
    } else if (!aProNbrTxt || aProNbrTxt.length === 0) {
      throw new IllegalArgumentError('invalid pro');
    } else {
      this.setBasePath(aCubiscanDevice, isSimulatorTargetted);
      let lProNbrTxt: string = aProNbrTxt;
      let lSerial: string = aCubiscanDevice.serialNumber;
      if (!lSerial || lSerial.length === 0) {
        throw new IllegalArgumentError('invalid serial for device');
      }
      if (isSimulatorTargetted) {
        // @ts-ignore
        let lRequest: ExtendedCubiscanDimensionerCsMeasureRequestBodyModel = {
          barcode1: lProNbrTxt,
          cubiscanSerial: lSerial,
          Barcode1: lProNbrTxt,
        };
        apiResult$ = this.cubiscanDimensionerMockControllerService.csMeasureData(lRequest);
      } else {
        console.debug('csMeasureData calling:');
        apiResult$ = this.csMeasureData(aCubiscanDevice, aProNbrTxt);
      }
      const result$: Observable<ExtendedCubiscanDeviceScanningResponseDto> = apiResult$.pipe(map((apiResult: any) => {
        console.debug('getDimensions$:', apiResult);
        // const lResult: ExtendedCubiscanDeviceScanningResponseDto = ExtendedCubiscanDeviceScanningResponseDto.fromResponseDto(apiResult);
        const lResult: ExtendedCubiscanDeviceScanningResponseDto = apiResult;
        return lResult;
      }));
      return result$;
    }
  }

  private getUrlWithSuffix(aCubiscanDevice: CubiscanDimensionerDevice, aUrlSuffix: string): string {
    if (!aCubiscanDevice) {
      throw new IllegalArgumentError('null aCubiscanDevice');
    } else if (!aUrlSuffix || aUrlSuffix.length === 0) {
      throw new IllegalArgumentError('null aUrlSuffix');
    } else {
      // let result: string = 'http://' + aCubiscanDevice.dnsName + ':' + aCubiscanDevice.restApiPort + '/' + aUrlSuffix;
      let result: string = 'http://' + aCubiscanDevice.dnsName + '/' + aUrlSuffix;
      return result;
    }
  }

  private csMeasure(aCubiscanDevice: CubiscanDimensionerDevice, aProNbrTxt: string): Observable<ExtendedCubiscanDeviceScanningResponseDto> {
    const lUrl: string = this.getUrlWithSuffix(aCubiscanDevice, 'csMeasure');
    const lReq: CubiscanGetDimensionsRequest = new CubiscanGetDimensionsRequest();
    lReq.Barcode1 = aProNbrTxt;
    const lHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      // 'Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,HEAD,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Content-Type, Accept, Accept-Language, Origin, User-Agent',
    });
    const lBody: any = lReq;
    const lOptions: any = {
      // headers: lHeaders
    };
    var result$: Observable<ExtendedCubiscanDeviceScanningResponseDto> = this.plainHttpClient
      .post<any>(lUrl, lBody, lOptions).pipe(
        take(1),
        map((aHttpEvent) => {
          let lResponseDto: ExtendedCubiscanDeviceScanningResponseDto = ExtendedCubiscanDeviceScanningResponseDto.fromCsMeasure(aHttpEvent);
          return lResponseDto;
        }),
        catchError((error) => {
          console.error('csMeasure error:', error);
          throw error;
        }));


    return result$;
  }


  private csMeasureData(aCubiscanDevice: CubiscanDimensionerDevice, aProNbrTxt: string): Observable<ExtendedCubiscanDeviceScanningResponseDto> {
    const lUrl: string = this.getUrlWithSuffix(aCubiscanDevice, 'csMeasureData');
    const lReq: CubiscanGetDimensionsRequest = new CubiscanGetDimensionsRequest();
    lReq.Barcode1 = aProNbrTxt;
    const lHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      // 'Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,HEAD,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Content-Type, Accept, Accept-Language, Origin, User-Agent',
    });
    const lBody: any = lReq;
    const lOptions: any = {
      // headers: lHeaders
    };
    console.log('csMeasureData->');
    var result$: Observable<ExtendedCubiscanDeviceScanningResponseDto> = this.plainHttpClient
      .post<any>(lUrl, lBody, lOptions).pipe(
        take(1),
        map((aHttpEvent) => {
          console.debug('csMeasureData->', aHttpEvent);
          let lResponseDto: ExtendedCubiscanDeviceScanningResponseDto = ExtendedCubiscanDeviceScanningResponseDto.fromCsMeasureData(aHttpEvent);
          return lResponseDto;
        }),
        catchError((error) => {
          console.error('csMeasureData error:', error);
          return of(this.catchedCsMeasureDataParsingError(error));
          // throw error
        }));


    return result$;
  }

  protected setBasePath(aCubiscanDevice: CubiscanDimensionerDevice, isSimulatorTargetted: boolean) {
    let lBasePath: string;
    if (isSimulatorTargetted) {
      lBasePath = this.constructorBasePath;
    } else {
      lBasePath = aCubiscanDevice.getApiBasePath();
    }
    console.log('setBasePath->changing config to path:' + lBasePath);
    this.cubiscanDimensionerMockControllerService.configuration.basePath = lBasePath;
  }

  private catchedCsMeasureDataParsingError(srcError: any): ExtendedCubiscanDeviceScanningResponseDto {
    console.debug('catchedCsMeasureDataParsingError->', srcError);
    if (srcError?.error?.text) {
      let srcErrorUnparsableTxt: string = srcError?.error.text;
      console.debug('catchedCsMeasureDataParsingError-> unparsableTxt:', srcErrorUnparsableTxt);
      let parsableTxt: string = srcErrorUnparsableTxt.replace('"dim_weight_factor": ,', '');
      let lAny: any = JSON.parse(parsableTxt);
      console.debug('catchedCsMeasureDataParsingError-> corrected json object:', JSON.stringify(lAny));
      let lResponseDto: ExtendedCubiscanDeviceScanningResponseDto = ExtendedCubiscanDeviceScanningResponseDto.fromCsMeasureData(lAny);
      return lResponseDto;
    } else {
      throw new Error('unexpected unrecoverable csMeasureData error', srcError);
    }
  }
}
