<div class="form-wrapper" style="margin-top: 0.8em;">
  <form class="tab-body tab-filters" [formGroup]="filterFormGroup" autocomplete="off">
    <!--    Select device-->
    <mat-form-field floatLabel="always">
      <mat-label>Selected device</mat-label>
      <mat-select
        placeholder="Device"
        xpoSelect
        [compareWith]="compareDevicesSelectFunction"
        [formControlName]="formNames.DEVICE_SELECT"
      >
        <mat-option *ngFor="let item of (devicesList$|async)" [value]="item.value" [matTooltip]="item.matTooltip" matTooltipPosition="right">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!--    pro-->
    <mat-form-field class="filter-field-wrapper " floatLabel="always">
      <mat-label>PRO Number</mat-label>
      <input
        #proNumberInput
        [formControlName]="formNames.PRO_NBR"
        matInput
        data-test="pro-nbr-input"
        type="text"
        autofocus
      />
      <mat-error *ngIf="proNbrInvalidFormat">
        Enter a valid Pro number format (e.g. 123456789, 123-456789, 01230456789)
      </mat-error>
    </mat-form-field>
    <!--    Length -->
    <mat-form-field class="filter-field-wrapper " floatLabel="always" style="margin-left: 2em;">
      <mat-label>Length</mat-label>
      <input [formControlName]="formNames.MANUAL_LENGTH" matInput type="text" autofocus value="11" />
      <mat-error *ngIf="proNbrInvalidFormat">
        Enter a valid Length
      </mat-error>
    </mat-form-field>
    <!--    Width-->
    <mat-form-field class="filter-field-wrapper " floatLabel="always">
      <mat-label>Width</mat-label>
      <input [formControlName]="formNames.MANUAL_WIDTH" matInput data-test="pro-nbr-input" type="text" autofocus />
      <mat-error *ngIf="proNbrInvalidFormat">
        Enter a valid Width
      </mat-error>
    </mat-form-field>
    <!--    Height -->
    <mat-form-field class="filter-field-wrapper " floatLabel="always">
      <mat-label>Height</mat-label>
      <input [formControlName]="formNames.MANUAL_HEIGHT" matInput type="text" autofocus />
      <mat-error *ngIf="proNbrInvalidFormat">
        Enter a valid Height
      </mat-error>
    </mat-form-field>
    <!--    Height -->
    <mat-form-field class="filter-field-wrapper" floatLabel="always" style="margin-left: 2em;">
      <mat-label>Comment</mat-label>
      <input [formControlName]="formNames.COMMENT" matInput type="text" autofocus />
    </mat-form-field>
<!--    <h2>Simulation</h2>-->
<!--    <xpo-button-group>-->
<!--      <button type="button" class="btn-display" mat-stroked-button (click)="onCreateDimensionsClicked(true)" [disabled]="!(isDimensionerActionEnabledBs$|async)">-->
<!--        Scan and store dimensions-->
<!--      </button>-->
<!--      <button type="button" class="btn-display" mat-stroked-button (click)="onScanOnlyClicked(true)" [disabled]="!(isDimensionerActionEnabledBs$|async)">-->
<!--        Scan only (No DB storage)-->
<!--      </button>-->
<!--    </xpo-button-group>-->
    <h2>Device</h2>
    <xpo-button-group>
      <button type="button" class="btn-display" mat-stroked-button (click)="onCreateDimensionsClicked()" [disabled]="!(isDimensionerActionEnabledBs$|async)">
        Scan and store dimensions
      </button>
      <button type="button" class="btn-display" mat-stroked-button (click)="onScanOnlyClicked()" [disabled]="!(isDimensionerActionEnabledBs$|async)">
        Scan only (No DB storage)
      </button>
      <button type="button" class="btn-clear" mat-stroked-button (click)="onResetFormClicked()">Clear Form</button>
    </xpo-button-group>
<!--    <h2>Testing/debugging</h2>-->
<!--    <xpo-button-group>-->
<!--      <button type="button" class="btn-display" mat-stroked-button (click)="onCsMeasureClicked()" [disabled]="!(isDimensionerActionEnabledBs$|async)">-->
<!--        csMeasure (Start)-->
<!--      </button>-->
<!--      <button type="button" class="btn-display" mat-stroked-button (click)="onCsMeasureDataClicked()" [disabled]="!(isDimensionerActionEnabledBs$|async)">-->
<!--        csMeasureData (Reading loop -> minimum 1.5 seconds after start)-->
<!--      </button>-->
<!--    </xpo-button-group>-->
  </form>
  <div class="status">
    <strong>Status:</strong> {{currentStatusString$|async}}
  </div>
  <h1>
    Logs
    <button type="button" class="btn-clear" mat-stroked-button (click)="onClearLogsClicked()">Clear Logs</button>
  </h1>
  <div style="border: 1px solid #000; height: 60%; overflow: scroll">
    <div *ngFor="let item of logsContent$ | async" style="border-top: 1px dotted rgba(0,0,0,0.73);">
      <strong>{{ item.createDate| date: 'dd/MM/yyyy hh:mm a' }}-></strong>{{item.text}}
      <div class="image"
           *ngIf="item.hasImage()"
      >
        <img [src]="item.base64Src1" style="width: auto; height: auto; max-height: 10em;margin-right: 3px;" (click)="onImageClicked($event)" matTooltip="Click to zoom in/out" />
        <img [src]="item.base64Src2" style="width: auto; height: auto; max-height: 10em;margin-right: 3px;" (click)="onImageClicked($event)" matTooltip="Click to zoom in/out" />
        <img [src]="item.base64SrcOvv" style="width: auto; height: auto; max-height: 10em;" (click)="onImageClicked($event)" matTooltip="Click to zoom in/out" />
      </div>
    </div>
  </div>
</div>
