import { Injectable } from '@angular/core';
import { XpoApiServiceBase } from './xpo-api-service-base';
import {
  DmsV2ControllerService, ExtendedDocumentDto,
  ExtendedDocumentSearchDto,
} from '../../../../../../../../libs/libs/ltl-java-opsinspections-rest';
import { DimensionerAppConfigurationService } from './dimensioner-app-configuration.service';
import { map, Observable, take } from 'rxjs';
import { List } from 'immutable';
import { DateHelper } from '../helpers/date-helper';

@Injectable({
  providedIn: 'root',
})

export class OpsInspectionsApiWrapperService extends XpoApiServiceBase{

  public static readonly WRFO_CLASS: string = 'WRFO'

  constructor(protected dimensionerAppConfigurationService: DimensionerAppConfigurationService,
              protected dmsV2ControllerService: DmsV2ControllerService) {
    super();
    this.setBasePathIfLocal(this.dmsV2ControllerService, this.dimensionerAppConfigurationService.isOpsInspectionsApiAgainstLocalhost());
  }

  findDocuments$(aProNbr9Digit: string, aStartFromDate: Date, aItemsCount: number): Observable<List<ExtendedDocumentDto>> {
    const afterDateTimePortland: any = DateHelper.toApiDateTime(aStartFromDate)
    return this.dmsV2ControllerService.findBySearchString(aProNbr9Digit, OpsInspectionsApiWrapperService.WRFO_CLASS, afterDateTimePortland, aItemsCount).pipe(
      take(1),
      map((resp: ExtendedDocumentSearchDto[]) => {
        console.debug('findDocuments$ for pro:' + aProNbr9Digit, resp);
        return List(resp);
      }),
    );
  }


}
