import { NgModule } from '@angular/core';
import { XpoComponentsModule } from '../../../../xpo-components.module';
import { NgMaterialModule } from '../../ng-material.module';

const components = [];


@NgModule({
  declarations: components,
  exports: [...components,
    NgMaterialModule,
  ],
  imports: [
    //forms
    XpoComponentsModule,
    NgMaterialModule,
  ],
  providers: [],
})
export class UiEntityMatTableModule {
}
