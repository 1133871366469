/**
 * Generic class for custom errors
 */
export class DimensionerError extends Error {
  constructor(aMessage: string, aCause?: any) {
    super(aMessage); // call the parent constructor
    this.name = 'DimensionerError'; // set the name property
    this.cause = aCause;
  }
}
