import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './dimensioner/shared/lib/enums/app-routes.enum';
import { DimensionsViewComponent } from './dimensioner/shared/components/dimensions-view/dimensions-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: AppRoutes.DIMENSIONERS_DASHBOARD,
    pathMatch: 'full'
  },
  {
    path: AppRoutes.DIMENSIONERS_DASHBOARD,
    loadChildren: () => import('./dimensioner/shared/dimensioner-shared.module').then((m) => m.DimensionerSharedModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.DIMENSIONS_VIEW,
    component: DimensionsViewComponent,
    canActivate: [XpoAuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
