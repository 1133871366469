import { DateHelper } from '../helpers/date-helper';

export abstract class DimensioningOperation {

  protected _proNumber: string;
  protected _manualLength: number;
  protected _manualWidth: number;
  protected _manualHeight: number;
  protected _comment: string;

  constructor(proNumber: string, length: number, width: number, height: number, aComment: string) {
    this._proNumber = proNumber;
    this._manualLength = length;
    this._manualWidth = width;
    this._manualHeight = height;
    this._comment = aComment;
  }


  toLog(): string {
    let result: string = '';
    result += ' pro:' + this._proNumber;
    result += ' length:' + this._manualLength;
    result += ' width:' + this._manualWidth;
    result += ' height:' + this._manualHeight;
    result += ' comment:' + this._comment;
    result += this.toLogsSuffix();
    return result;
  }

  protected toLogsSuffix(): string {
    return '';
  }


  get proNumber(): string {
    return this._proNumber;
  }

  get manualLength(): number {
    return this._manualLength;
  }

  get manualWidth(): number {
    return this._manualWidth;
  }

  get manualHeight(): number {
    return this._manualHeight;
  }

  get comment(): string {
    return this._comment;
  }
}
