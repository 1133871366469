import { DimsCubiscanDashboardComponent } from './components/dims-cubiscan-dashboard/dims-cubiscan-dashboard.component';
import { NgModule } from '@angular/core';
import { CubiscanDeviceApiService } from './lib/services/cubiscan-device-api.service';
import { XpoComponentsModule } from '../../xpo-components.module';
import { RouterModule, Routes } from '@angular/router';
import { DimensionsViewComponent } from './components/dimensions-view/dimensions-view.component';
import { ApiErrorHandlerService } from './lib/services/api-error-handler.service';
import {
  UiEntityMatTableDirectivesModule,
} from './lib/ui-entity-mat-table/directives/ui-entity-mat-table-directives.module';
import { UiEntityMatTableModule } from './lib/ui-entity-mat-table/ui-entity-mat-table.module';
import { DimensionsTableComponent } from './components/dimensions-table/dimensions-table.component';
import { ProNumberPipe } from './pipes/pro-number.pipe';
import { FormatNumberOrNaPipe } from './pipes/format-number-or-na.pipe';
import { PortlandDateOrNa } from './pipes/portland-date-or-na.pipe';
import { PortlandDatePipe } from './pipes/portland-date.pipe';
import { YesNoOptionPipeOrNa } from './pipes/yes-no-option/yes-no-option-or-na.pipe';
import { YesNoOptionPipe } from './pipes';
import { DimensionsTableCustomComponent } from './components/dimensions-table-custom/dimensions-table-custom.component';
import { DevicesService } from './lib/services/devices.service';
import { UserNotificationService } from './lib/services/user-notification.service';
import { NgMaterialModule } from './ng-material.module';
import { DmsApiWrapper } from './lib/services/dms-api-wrapper.service';

const components = [
  YesNoOptionPipe,
  YesNoOptionPipeOrNa,
  PortlandDatePipe,
  PortlandDateOrNa,
  FormatNumberOrNaPipe,
  ProNumberPipe,

  DimsCubiscanDashboardComponent,
  DimensionsViewComponent,
  DimensionsTableComponent,
  DimensionsTableCustomComponent,
];

const routerDefinitions: Routes = [
  {
    path: '',
    component: DimsCubiscanDashboardComponent,
  },
];

@NgModule({
  declarations: components,
  exports: [...components],
  imports: [
    //material modules
    NgMaterialModule,
    //forms
    XpoComponentsModule,
    RouterModule.forChild(routerDefinitions),
    UiEntityMatTableDirectivesModule,
    UiEntityMatTableModule,
  ],
  providers: [
    CubiscanDeviceApiService,
    ApiErrorHandlerService,
    DevicesService,
    UserNotificationService,
    DmsApiWrapper,
  ],
})
export class DimensionerSharedModule {
}
