import { IllegalArgumentError } from '../classes/error/illegal-argument-error';
import { ProNumberUtil } from './pro-number-util';

/**
 * just for dms 9 digit pro todo: replaceme with API pro
 */
export class ProNumberHelper {
  static getStandardizedProNbr(src: string | undefined): string {
    let result: string = '';
    if (src) {
      if (typeof src === 'string' || !isNaN(src)) {
        ProNumberUtil.validateProNbrTxtAndNotNull(src);
        result = ProNumberUtil.getHyphenedProNbrTxt(src);
      } else {
        const msg: string = 'unrecognized type for src';
        console.error(msg, src);
        throw new IllegalArgumentError(msg);
      }
    }
    return result;
  }

  /**
   * Call me only for APIS we dont want that to be called for UI pro formatting but `getStandardizedProNbr`
   * @param aProNbrTxt
   */
  static getNineDigitString(aProNbrTxt: string): string {
    return ProNumberUtil.get9Digit(aProNbrTxt);
  }
}
