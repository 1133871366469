import { Injectable } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { UserNotificationService } from './user-notification.service';

@Injectable({
  providedIn: 'root',
})
export class ApiErrorHandlerService {


  constructor(protected userNotificationService: UserNotificationService) {
  }

  handleError(srcError: any) {
    console.error('ApiErrorHandlerService error:', srcError);
    let lError: any = UserNotificationService.toSnackbarString(srcError);
    this.userNotificationService.error(lError)
  }


}
