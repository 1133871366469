import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { List } from 'immutable';
import { SelectOptionItem } from '../uiclasses/select-option-item';
import { CubiscanConstants } from '../classes/cubiscan-constants';
import { CubiscanDimensionerDevice } from '../classes/cubiscan-dimensioner-device';
import { IllegalArgumentError } from '../classes/error/illegal-argument-error';
import { LogItem } from '../classes/log-item';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DimensionerAppConfigurationService } from './dimensioner-app-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class DimensionerDataService {

  protected logsContentBs$: BehaviorSubject<List<LogItem>> = new BehaviorSubject<List<LogItem>>(List());
  logsContent$: Observable<List<LogItem>> = this.logsContentBs$.asObservable();


  constructor(protected dimensionerAppConfigurationService: DimensionerAppConfigurationService) {
    this.initialize();
  }

  protected initialize() {
  }

  addLog(val: string, abase64Src1?: SafeResourceUrl, abase64Src2?: SafeResourceUrl, aBase64SrcOvv?: SafeResourceUrl) {
    let lList: List<LogItem> = this.logsContentBs$.value;
    if (this.dimensionerAppConfigurationService.isNotKeepingImagesButLastInLogs() && (abase64Src1 || abase64Src2||aBase64SrcOvv)) {
      //image was sent we want it to be removed for performances but for last item
      lList = this.removedLatestBase64Images(lList)
    }
    lList = lList.push(new LogItem(val, abase64Src1, abase64Src2, aBase64SrcOvv));
    console.log('addLog:', val);
    this.logsContentBs$.next(lList);
  }

  clearLogs(){
    this.logsContentBs$.next(List())
  }

  private removedLatestBase64Images(aList: List<LogItem>): List<LogItem> {
    const logItem: LogItem = aList.last();
    if (logItem) {
      if (logItem.base64Src1) {
        logItem.base64Src1 = undefined;
      }
      if (logItem.base64Src2) {
        logItem.base64Src2 = undefined
      }
      if (logItem.base64SrcOvv) {
        logItem.base64SrcOvv = undefined
      }
    }
    return aList;
  }
}
