export class NavigationItem{


  constructor(label: string, routerLink: string, tooltip?: string) {
    this.label = label;
    this.routerLink = routerLink;
    this.tooltip = tooltip;
  }

  label: string
  routerLink: string
  tooltip?: string

}
