import { CubiscanDimensionerDevice } from './cubiscan-dimensioner-device';

export class CubiscanConstants {
  static readonly SIC_CODE_GOODLETTSVILLE: string = 'NGO'

  static readonly DEVICE_GOODLETTSVILLE_1: CubiscanDimensionerDevice = new CubiscanDimensionerDevice(
    'APA489-9',
    'Goodlettsville',
    '00-90-E9-00-6F-17',
    '10.232.232.5',
    '80',
    CubiscanConstants.SIC_CODE_GOODLETTSVILLE
  );
  static readonly DEVICE_GOODLETTSVILLE_2: CubiscanDimensionerDevice = new CubiscanDimensionerDevice(
    'APA489-10',
    'Goodlettsville',
    '00-90-E9-00-6C-2A',
    '10.232.232.6',
    '80',
    CubiscanConstants.SIC_CODE_GOODLETTSVILLE
  );
  static readonly DIMENSIONER_DEVICES: CubiscanDimensionerDevice[] = [
    CubiscanConstants.DEVICE_GOODLETTSVILLE_1,
    CubiscanConstants.DEVICE_GOODLETTSVILLE_2,
  ];
  static MANUAL_LENGTH_MAX: number = 1_000;
  static MANUAL_WIDTH_MAX: number = 1_000;
  static MANUAL_HEIGHT_MAX: number = 1_000;
}
