import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiRequest, HttpOptions } from '@xpo-ltl/data-api';
import {
  DocumentManagementApiService,
  DocumentSearch,
  GetDocumentPath,
  GetDocumentResp,
  RetrieveDmsAuthTokenResp,
  RetrieveDmsAuthTokenRqst,
  SearchDmsDocumentResp,
  SearchDmsDocumentRqst,
} from '@xpo-ltl/sdk-documentmanagement';
import { isEmpty as _isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DimensionerDmsDocument } from '../classes/api/dms/dimensioner-dms-document';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { UserNotificationService } from './user-notification.service';
import { ProNumberHelper } from '../helpers/pro-number-helper';

@Injectable({
  providedIn: 'root',
})
export class DmsApiWrapper {
  readonly docClass: string = 'WRFO';
  protected dmsCorpCode: string = 'DEMO';

  constructor(
    private dmsService: DocumentManagementApiService,
    private configManagerService: ConfigManagerService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    protected userNotificationService: UserNotificationService,
  ) {
  }

  // method to initiate ReweighLogSummaryService requests
  getDmsDocument$(aProNbr: string, logCreationDate: Date): Observable<DimensionerDmsDocument> {
    const proNbrNineDigit: string = ProNumberHelper.getNineDigitString(aProNbr);
    let currentDmsToken: string;
    return this.getDmsAuthToken$().pipe(
      take(1),
      switchMap((token: string) => {
        if (token) {
          currentDmsToken = token;
          return this.searchDmsMostRecentDocument$(proNbrNineDigit, logCreationDate, currentDmsToken);
        } else {
          const lErr: Error = new Error('Unable to get token from Document Management System')
          this.apiErrorHandlerService.handleError(lErr);
          throw lErr
        }
      }),
      switchMap((dmsDocument: DimensionerDmsDocument) => {
        if (!_isEmpty(dmsDocument)) {
          return this.getDocumentResp$(dmsDocument, currentDmsToken).pipe(
            map((documentResp) => {
              dmsDocument.setFromDocumentRespData(documentResp);
              dmsDocument.decodeBase64Data();
              dmsDocument.buildFileName();

              return dmsDocument;
            }),
          );
        } else {
          const lErr: Error = new Error('Unable to get doc from Document Management System doc empty')
          this.apiErrorHandlerService.handleError(lErr);
          throw lErr
        }
      }),
    );
  }

  // Step 1: get dmsToken
  protected getDmsAuthToken$(): Observable<string> {
    const req = new RetrieveDmsAuthTokenRqst();

    return this.dmsService.retrieveDmsAuthToken(req).pipe(
      map((resp: RetrieveDmsAuthTokenResp) => {
        return resp.access_token;
      }),
    );
  }

  // Step 2: request documentInfo for a particular PRO number
  protected searchDmsMostRecentDocument$(proString: string, minDateTime: Date, dmsToken: string): Observable<DimensionerDmsDocument> {
    const req = new SearchDmsDocumentRqst();
    const httpOptions: HttpOptions = { headers: { DMSAuth: dmsToken } };
    req.corpCode = this.dmsCorpCode;
    req.searchString = proString;
    req.minDateTime = new Date(minDateTime).toISOString();
    req.dmsAuth = dmsToken;

    return this.dmsService.searchDmsDocument(req, ApiRequest.concealedCall, httpOptions).pipe(
      map((resp: SearchDmsDocumentResp) => {
        if (!_isEmpty(resp?.documentInfo)) {
          const dmsDocument: DimensionerDmsDocument = new DimensionerDmsDocument(proString);
          const mostRecentDocument: DocumentSearch = DimensionerDmsDocument.getMostRecentDocument(resp, this.docClass);
          dmsDocument.setFromDocumentSearchData(mostRecentDocument);
          return dmsDocument;
        } else {
          return null;
        }
      }),
    );
  }

  // Step 3: request documentData
  protected getDocumentResp$(document: DimensionerDmsDocument, dmsToken: string): Observable<GetDocumentResp> {
    const docPath: GetDocumentPath = new GetDocumentPath();
    const httpOptions: HttpOptions = { headers: { DMSAuth: dmsToken } };

    docPath.docClass = this.docClass;
    docPath.corpCode = this.dmsCorpCode;
    docPath.docArchiveTimestamp = document.getTimestamp();

    return this.dmsService.getDocument(docPath, { multiPartResp: 'false' }, null, httpOptions).pipe(
      map((resp: GetDocumentResp) => {
        let returnValue = null;
        if (_isEmpty(resp?.documentData)) {
          this.userNotificationService.error('No document data');
        } else {
          try {
            returnValue = resp;
          } catch {
            this.userNotificationService.error('Document data is not available');
          }
        }
        return returnValue;
      }),
    );
  }
}
