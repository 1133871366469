import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren, ViewEncapsulation,
} from '@angular/core';
import {
  UiEntityMatTableComponent,
} from '../../lib/ui-entity-mat-table/components/ui-entity-mat-table/ui-entity-mat-table-component';
import { ExtendedInsDimensionerLog } from '../../lib/ui-entity-mat-table/entities/extended-ins-dimensioner-log';
import { UiEntityTableCellDirective } from '../../lib/ui-entity-mat-table/directives/ ui-entity-table-cell-directive';
import { MatSort } from '@angular/material/sort';
import { List } from 'immutable';
import { UiEntity } from '../../lib/ui-entity-mat-table/entities/ui-entity';
import { UiEntities } from '../../lib/ui-entity-mat-table/entities/ui-entities';
import { DimensionsTableCustomComponent } from '../dimensions-table-custom/dimensions-table-custom.component';

@Component({
  selector: 'app-dimensions-table',
  templateUrl: '../../lib/ui-entity-mat-table/components/ui-entity-mat-table/ui-entity-mat-table.component.html',
  styleUrls: ['./dimensions-table.component.scss',
    '../../lib/ui-entity-mat-table/components/ui-entity-mat-table/ui-entity-mat-table.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // for @viewChild and inputs to work with inheritence
  providers: [{ provide: DimensionsTableComponent, useExisting: UiEntityMatTableComponent }],
})
export class DimensionsTableComponent extends UiEntityMatTableComponent<ExtendedInsDimensionerLog, DimensionsTableCustomComponent>
  implements AfterViewInit {

  @Input()
  tableDataAsList: List<ExtendedInsDimensionerLog>;


  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<ExtendedInsDimensionerLog>
  >;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(changeDetectorRef);
    this.isTitleSectionDisplayed = false;
  }


  getComponentFactoryToProject(): ComponentFactory<DimensionsTableCustomComponent> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<DimensionsTableCustomComponent> = this.componentFactoryResolver.resolveComponentFactory<
      DimensionsTableCustomComponent
    >(DimensionsTableCustomComponent);
    return lComponentFactoryToProject;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.debug('onChange logDetailsComp, dataSourceData:', changes, this.dataSource.data);
    if (changes['tableDataAsList']) {
      // input has changed, resetting with new list the datasource data
      let lNewData: ExtendedInsDimensionerLog[] = [];
      console.debug('this.tableDataAsList:', this.tableDataAsList);
      if (this.tableDataAsList && this.tableDataAsList.size > 0) {
        lNewData = this.tableDataAsList.toArray();
      } else {
        console.log('data received from API is empty or null');
      }
      this.dataSource.data = lNewData;
      this.changeDetectorRef.markForCheck();
    }
  }

  /* tslint:disable use-lifecycle-interface */

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // set what's needed as data
    setTimeout(() => {
      // disable expanded
      this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
      this.changeDetectorRef.markForCheck();
    });
    // each time one element with that directive has been inserted into the DOM, then do...
    this.uiEntityTableCellDirectiveViewChildren.changes.subscribe((aUiEntityTableCellDirectiveQryList: any) => {
      // note that we receive aUiEntityTableCellDirectiveQryList as argument,
      // but its the same as this.uiEntityTableCellDirectiveViewChildren so we can use that one as well
      setTimeout(() => {
        this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  getUiEntity(): UiEntity {
    return UiEntities.INS_DIMENSIONER_LOG;
  }

  getMainTitle(): string {
    return 'Dimension logs (' + this.tableDataAsList.size + ')';
  }

  isLoadDataButtonDisplayed(): boolean {
    return false;
  }

  // override
  getSortDefaultColumnName(): string {
    return 'crteTmst';
  }


  getExpandedUiEntity(): UiEntity {
    return UiEntities.INS_DIMENSIONER_LOG;
  }


}
