import { List } from 'immutable';
import { NavigationItem } from './navigation-item';
import { AppRoutes } from '../enums/app-routes.enum';

export class AppConstants{
  static NAVIGATION_ITEMS: List<NavigationItem> = List([
    new NavigationItem('Capture', AppRoutes.DIMENSIONERS_DASHBOARD),
    new NavigationItem('View', AppRoutes.DIMENSIONS_VIEW),
  ]);



}
