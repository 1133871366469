import { DimensionerApiWrapperService } from './dimensioner-api-wrapper.service';

export class ApiServiceBase{
  static readonly BASE_PATH_URL: string = 'http://localhost:8086';


  protected setBasePathIfLocal(aService: any, isLocalhostUsed: boolean) {
    if (isLocalhostUsed) {
      aService.configuration.basePath = DimensionerApiWrapperService.BASE_PATH_URL;
    }
    console.log('aService config goes to path->', aService.configuration.basePath);
  }

}
