/**
 *
 *
 identifier ITEM_ID
 packType ITEM_TYPE
 description DESCRIPTION
 netLength NET_LENGTH
 netWidth NET_WIDTH
 netHeight NET_HEIGHT
 netWeight NET_WEIGHT
 netVolume NET_VOLUME
 netDimWeight NET_DIM_WGT
 dimUnit DIM_UNIT
 wgtUnit WGT_UNIT
 volUnit VOL_UNIT
 factor FACTOR
 siteId SITE_ID
 timestamp TIME_STAMP
 optionalInfo1 OPT_INFO_1
 optionalInfo2 OPT_INFO_2
 optionalInfo3 OPT_INFO_3
 optionalInfo4 OPT_INFO_4
 optionalInfo5 OPT_INFO_5
 optionalInfo6 OPT_INFO_6
 optionalInfo7 OPT_INFO_7
 optionalInfo8 OPT_INFO_8
 imageFile IMAGE_FILE_NAME
 updated UPDATED
 */


export class CubiscanGetDimensionsRequest {

  identifier: string
  packType: string
  description: string
  netLength: string
  netWidth: string
  netHeight: string
  netWeight: string
  netVolume: string
  netDimWeight: string
  dimUnit: string
  wgtUnit: string
  volUnit: string
  factor: string
  siteId: string
  timestamp: string
  optionalInfo1: string
  optionalInfo2: string
  optionalInfo3: string
  optionalInfo4: string
  optionalInfo5: string
  optionalInfo6: string
  optionalInfo7: string
  optionalInfo8: string
  imageFile: string
  updated: string
  //extension as should be there
  Barcode1: string
  Weight_Value: string

}
