import { ChangeDetectionStrategy, Component } from '@angular/core';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from './dimensioner/shared/enums/config-manager-properties.enum';
import { List } from 'immutable';
import { NavigationItem } from './dimensioner/shared/lib/classes/navigation-item';
import { AppConstants } from './dimensioner/shared/lib/classes/app-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  build: string;
  navigationItems: List<NavigationItem> = AppConstants.NAVIGATION_ITEMS;

  constructor(
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe();
    this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }
}
