import {
  CubiscanDeviceScanningResponseDto,
  CubiscanDeviceScanningResponseMeasurementDto,
} from '../../../../../../../../../../libs/libs/ltl-java-dimensioner-rest';
import { IllegalArgumentError } from '../../error/illegal-argument-error';

export class ExtendedCubiscanDeviceScanningResponseDto implements CubiscanDeviceScanningResponseDto {

  measurement?: CubiscanDeviceScanningResponseMeasurementDto;

  public static fromResponseDto(srcAny: CubiscanDeviceScanningResponseDto): ExtendedCubiscanDeviceScanningResponseDto {
    if (!srcAny) {
      throw new IllegalArgumentError('null srcAny');
    }
    const result: ExtendedCubiscanDeviceScanningResponseDto = new ExtendedCubiscanDeviceScanningResponseDto();
    const srcAnyDataWrapper: any = srcAny;
    if (!srcAnyDataWrapper.data) {
      throw new IllegalArgumentError('null srcAny has no data');
    }
    Object.assign(result, srcAnyDataWrapper.data); // Object.assign(target, source)
    return result;
  }

  isValid(): boolean {
    let result: boolean = false;
    result = this.getStatusFromMeasurementOrUndefined() === 0

    return result;
  }


  toDimensionsString(): string {
    return `L:${this.measurement.net_length} W:${this.measurement.net_width} H:${this.measurement.net_height}`;
  }

  static fromCsMeasureData(src: any): ExtendedCubiscanDeviceScanningResponseDto {
    console.debug('fromCsMeasureData-> src:', src);
    let lStatus: number;
    let lMeasurementFromResp: any = src?.measurement;
    //todo pg: check that workaround, it should never happen!
    //treat status depending on what we get
    if (src.csMeasureData?.Status) {
      console.warn('fromCsMeasureData status got from uppercase status happening' +
        ' when csMeasure has not been called or finished status:' + src.csMeasureData?.Status + ' src:', src);
      lStatus = +src.csMeasureData.Status;
    } else if (src.csMeasureData?.status) {
      console.warn('fromCsMeasureData status got from lowercase status:', src);
      lStatus = +src.csMeasureData.status;
    } else if (src.measurement?.status || !isNaN(src.measurement?.status)) {
      console.warn('fromCsMeasureData status got from measurement, meaning we should have a valid one status:', src);
      lStatus = src.measurement?.status;
    } else {
      throw new Error('status not found in src');
    }
    //treat message
    const result: ExtendedCubiscanDeviceScanningResponseDto = new ExtendedCubiscanDeviceScanningResponseDto();

    // not working, might if time dedicated :)
    // const srcAsDto:CubiscanDeviceScanningResponseMeasurementDto = src as CubiscanDeviceScanningResponseMeasurementDto;
    // console.debug('fromCsMeasureData-> srcAsDto:', srcAsDto)
    // result.measurement = srcAsDto

    result.measurement = new class implements CubiscanDeviceScanningResponseMeasurementDto {
      barcode1: string = lMeasurementFromResp?.barcode1;
      barcode2: string = lMeasurementFromResp?.barcode2;
      cubiscan_id: string = lMeasurementFromResp?.cubiscan;
      date_time: string = lMeasurementFromResp?.date_time;
      date_time_iso: string = lMeasurementFromResp?.date_time_iso;
      dim_weight_factor: number;
      dimensional_unit: string = lMeasurementFromResp?.dimensional_unit;
      image_file_1: string = lMeasurementFromResp?.image_file_1;
      image_file_2: string = lMeasurementFromResp?.image_file_2;
      image_file_ovv: string = lMeasurementFromResp?.image_file_ovv;
      message: string = lMeasurementFromResp?.message;
      net_dim_weight: number;
      net_height: number = lMeasurementFromResp?.net_height;
      net_length: number = lMeasurementFromResp?.net_length;
      net_real_volume: number;
      net_volume: number;
      net_weight: number;
      net_width: number = lMeasurementFromResp?.net_width;
      site_id: string;
      status: number = lStatus;
      valid: boolean;
      volume_unit: string;
      weight_unit: string;
    }();
    console.debug('fromCsMeasureData:', src);
    return result;
  }

  static fromCsMeasure(src: any): ExtendedCubiscanDeviceScanningResponseDto {
    //todo pg: work on me
    const result: ExtendedCubiscanDeviceScanningResponseDto = new ExtendedCubiscanDeviceScanningResponseDto();
    result.measurement = new class implements CubiscanDeviceScanningResponseMeasurementDto {
      barcode1: string;
      barcode2: string;
      cubiscan_id: string;
      date_time: string;
      date_time_iso: string;
      dim_weight_factor: number;
      dimensional_unit: string;
      image_file_1: string;
      image_file_2: string;
      message: string;
      net_dim_weight: number;
      net_height: number;
      net_length: number;
      net_real_volume: number;
      net_volume: number;
      net_weight: number;
      net_width: number;
      site_id: string;
      status: number = +src.csMeasure.Measure_Status;
      valid: boolean;
      volume_unit: string;
      weight_unit: string;
    }();
    console.debug('fromCsMeasure src, result:', src, result);
    return result;
  }

  hasError(): boolean {
    const lStatus: number = this.getStatusFromMeasurementOrUndefined();
    return lStatus !== 0 && lStatus !== 1
  }

  getStatusFromMeasurementOrUndefined(): number|undefined {
    let result: number|undefined = undefined
    const statusAny: any = this.measurement?.status;
    if (!isNaN(this.measurement?.status)) {
      const statusNumber: number = +statusAny;
      result = statusNumber;
    } else {
      console.error('could not read status from cubi API response', this);
    }
    return result
  }

  getErrorMessageForHasError(): string {
    let result: string = ''
    const lStatus: number = this.getStatusFromMeasurementOrUndefined()
    if (lStatus !== 0) {
      if (lStatus === 505) {
        //we might want to treat that another way 'Zero Dimensions Warning'
        result = this.measurement?.message
      }else if (lStatus === 1) {
        result = 'status is 1, you might not have called csMeasure'
      }else{
        result = this.measurement?.message
      }
    }
    return result;
  }
}
