import { UiEntityTableEntity } from './ui-entity-table-entity';
import { InsDimensionerLogDto } from '../../../../../../../../../libs/libs/ltl-java-dimensioner-rest';
import { IllegalArgumentError } from '../../classes/error/illegal-argument-error';

export class ExtendedInsDimensionerLog implements InsDimensionerLogDto, UiEntityTableEntity {
  adjustedDensityPsf: number;
  adjustedHeightNbr: number;
  adjustedLengthNbr: number;
  adjustedWidthNbr: number;
  corrReqInstId: number;
  correctedDensityPsf: number;
  correctedNmfcClass: string;
  correctedNmfcItemNbrTxt: string;
  correctedVolCft: number;
  correlationId: string;
  crteBy: string;
  crtePgmId: string;
  crteTmst: Date;
  dimTmst: Date;
  dimensionerId: string;
  dimensionerLogId: string;
  exceptionCd: string;
  exceptionDesc: string;
  forkliftNbr: number;
  forkliftOperatorId: string;
  heightNbr: number;
  insEventId: number;
  lengthNbr: number;
  lstUpdtBy: string;
  lstUpdtPgmId: string;
  lstUpdtTmst: Date;
  measurementId: number;
  originalDensityPsf: number;
  originalNmfcClass: string;
  originalNmfcItemNbrTxt: string;
  originalVolCft: number;
  pkupDt: string;
  proNbr: string;
  recordVersionNbr: number;
  revenueCorrectedAmt: number;
  revenueOriginalAmt: number;
  revenuePreviewAmt: number;
  shpInstId: number;
  sicCode: string;
  statusCd: string;
  weightLbs: number;
  widthNbr: number;


//added here only
  deltaL: number;
  deltaW: number;
  deltaH: number;
  operationDurationString: string;

  static toExtendedInsDimensionerLog(srcAny: any): ExtendedInsDimensionerLog {
    if (!srcAny) {
      throw new IllegalArgumentError('null srcAny');
    } else {
      const result: ExtendedInsDimensionerLog = new ExtendedInsDimensionerLog();
      Object.assign(result, srcAny); // Object.assign(target, source)
      this.setExtendedProperties(result);
      return result;
    }
  }


  protected static setExtendedProperties(target: ExtendedInsDimensionerLog) {
    //if (target.lengthNbr && target.widthNbr &&)
    const lLdiff: number = target.adjustedLengthNbr - target.lengthNbr;
    const lWdiff: number = target.adjustedWidthNbr - target.widthNbr;
    const lHdiff: number = target.adjustedHeightNbr - target.heightNbr;
    target.deltaL = lLdiff;
    target.deltaW = lWdiff;
    target.deltaH = lHdiff;
    if (target.corrReqInstId) {
      target.operationDurationString = (target.corrReqInstId / 1000).toFixed(3) + ' s';
      target.operationDurationString = target.operationDurationString.replace(',', '.')
    }
  }
}
