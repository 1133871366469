import { ExtendedDocumentDto } from 'libs/libs/ltl-java-opsinspections-rest';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MiscUtil } from '../../../ui-entity-mat-table/utils/misc-util';
import { IllegalStateError } from '../../error/illegal-state-error';

export class OpsInspectionExtendedDs implements ExtendedDocumentDto{
  base64data: string;
  corpCode: string;
  relevanceValue: number;
  timestamp: string;
  timestampIso: string;
  timestampPortland: Date;

  base64Sanitized: SafeResourceUrl


  constructor() {
  }

  setSanitizedBase64Data(sanitizer: DomSanitizer) {
    if (!this.base64data || this.base64data?.length === 0) {
      throw new IllegalStateError('this.base64data is null or empty')
    }
    this.base64Sanitized = MiscUtil.getBase64ImageSafeResourceUrl(this.base64data, sanitizer);
  }

  static fromExtendedDocumentDto(src: ExtendedDocumentDto): OpsInspectionExtendedDs {
    const result: OpsInspectionExtendedDs = new OpsInspectionExtendedDs()
    result.base64data = src.base64data
    result.timestampIso = src.timestampIso
    result.corpCode = src.corpCode
    result.relevanceValue = src.relevanceValue

    return result;
  }
}
