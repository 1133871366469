import { formatInTimeZone } from 'date-fns-tz';
import { addSeconds, format, intervalToDuration, parse } from 'date-fns';
import { IllegalArgumentError } from '../classes/error/illegal-argument-error';

/**
 * parse doc: https://date-fns.org/v3.6.0/docs/parse
 */
export class DateHelper {
  static readonly PORTLAND_TIMEZONE_STRING: string = 'America/Los_Angeles'; // PST PDT
  static readonly DEFAULT_MDY_FORMAT: string = 'MM-dd-yyyy';
  static readonly DEFAULT_BACKEND_DATE_STRING_FORMAT: string = 'YYYY-MM-dd';
  static readonly DEFAULT_YYYYMMDD_HHMMSS: string = 'yyyyMMdd_HHmmss';
  static readonly DEFAULT_UI_DATE_OUTPUT_FORMAT: string = 'MM/dd/yyyy';
  static readonly ANGULAR_PIPE: string = 'MM/dd/yyyy HH:mm:ss';
  static readonly TO_API_DATE_TIME_FORMAT: string = 'yyyy-MM-dd HH:mm:ss';
  //"2024-05-20T13:01:25.152314"
  static readonly FROM_API_DATE_TIME_FORMAT: string = "yyyy-MM-dd'T'HH:mm:ss.SSSS";
  static readonly HH_MM_SS: string = 'HH:mm:ss';
  static readonly DEFAULT_NULL_VALUE: string = 'N/A';

  /**
   * Format date, based on default application PORTLAND_TIMEZONE_STRING
   * @default format = 'MM-DD-YYYY'
   */
  static getPortandTzFormattedDate(date: Date, format: string = DateHelper.DEFAULT_MDY_FORMAT): string {
    let result: string = '';
    if (date) {
      result = formatInTimeZone(date, DateHelper.PORTLAND_TIMEZONE_STRING, format);
    }
    return result;
  }

  /**
   * Adds one second and returns the date with given format
   *
   * @param date
   * @param format
   */
  static getDatePickerFormattedDate(date: Date, aFormat: string = DateHelper.DEFAULT_MDY_FORMAT): string {
    // adding one second because if 00:00:00 then gives us the day before
    let lDate: Date = addSeconds(date, 1);
    const result: string = format(lDate, aFormat);

    return result;
  }

  // Getting 'No overload matches this call.' error so commenting this out for now since there is no usage.
  // static getFormatDateFromSting(date: String, format: string = 'MM-DD-YYYY'): Moment {
  //   return moment(date, format);
  // }

  static getDayBefore(date: Date): Date {
    const dayBefore = new Date(date);
    dayBefore.setDate(dayBefore.getDate() - 1);
    return dayBefore;
  }

  /**
   * Return today date, but without time
   */
  static getToday(): Date {
    const result: Date = new Date();
    return result;
  }

  static addDays(date: Date, daysToAdd): Date {
    const dateToReturn = new Date(date);
    dateToReturn.setDate(dateToReturn.getDate() + daysToAdd);
    return dateToReturn;
  }

  static removeDays(date: Date, daysToRemove): Date {
    const dateToReturn = new Date(date);
    dateToReturn.setDate(dateToReturn.getDate() - daysToRemove);
    return dateToReturn;
  }

  static addMinutes(date: Date, minutesToAdd: number): Date {
    const dateMs = date.getTime();
    const msToAdd = minutesToAdd * 6000;
    const newDateMs = dateMs + msToAdd;
    return new Date(newDateMs);
  }

  static addMilliseconds(date: Date, msToAdd: number): Date {
    const dateMs = date.getTime();
    const newDateMs = dateMs + msToAdd;
    return new Date(newDateMs);
  }

  static getDifferenceInMinutes(dateFrom: Date, dateTo: Date): number {
    const dateFromMs = dateFrom.getTime();
    const dateToMs = dateTo.getTime();
    const differenceMs = dateToMs - dateFromMs;
    if (!differenceMs || differenceMs <= 0) {
      return 0;
    }
    return Math.round(differenceMs / 6000);
  }

  static getHHMMSS(src: Date): string {
    return format(src, this.HH_MM_SS);
  }

  static passedTimeStringTillNowHmS(aDate: Date): string {
    let result: string = '';
    const { hours, minutes, seconds } = intervalToDuration({
      start: aDate,
      end: new Date(),
    });
    if (hours) {
      result += hours + 'h ';
    }
    if (minutes) {
      result += minutes + 'min ';
    }
    if (seconds) {
      result += seconds + 'sec ';
    }
    return result;
  }

  static toApiDateTime(aStartFromDate: Date): string {
    console.log('toApiDateTime->', aStartFromDate)
    return format(aStartFromDate, this.TO_API_DATE_TIME_FORMAT)
  }

  /**
   *
   * @param src string example: ''
   */
  static fromApiDateTime(src: any): Date {
    if (typeof src === 'string' || src instanceof String) {
      const lTmstString: string = src.toString()
      const resultEpoch = Date.parse(lTmstString)
      const result: Date = new Date(resultEpoch)
      // const result: Date = parse(lTmstString, "yyyy-MM-dd'T'HH:mm:ss", new Date())
      if (!result) {
        throw new IllegalArgumentError('unparsable src')
      }
      return result;
    }else{
      throw new IllegalArgumentError('invalid argument type')
    }

  }
}
