import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DimensionerAppConfigurationService{

  // use dimensioner API on localhost, if false go on configured one on config.json URLs //PROD: false
  static readonly IS_USE_DIMENSIONER_API_ON_LOCALHOST: boolean = false;
  //use dimensioner devices agains localhost simulator or real devices //PROD: false
  static readonly IS_USE_DIMENSIONER_DEVICE_API_ON_LOCALHOST: boolean = false;
  //keeps all images into dimensioner-data.service.ts //PROD: false
  static readonly IS_KEEPING_ALL_IMG_INTO_LOGS: boolean = false;
  //use ops inspections API on localhost, if false go on configured one on config.json URLs //PROD: false
  static readonly IS_USE_OPS_INSPECTIONS_API_ON_LOCALHOST: boolean = false;

  constructor() {
  }


  isDimensionerApiAgainstLocalhost(): boolean {
    return DimensionerAppConfigurationService.IS_USE_DIMENSIONER_API_ON_LOCALHOST;
  }

  isDimensionerDeviceApiAgainstLocalhost(): boolean {
    return DimensionerAppConfigurationService.IS_USE_DIMENSIONER_DEVICE_API_ON_LOCALHOST;
  }

  isNotKeepingImagesButLastInLogs(): boolean {
    return !DimensionerAppConfigurationService.IS_KEEPING_ALL_IMG_INTO_LOGS;
  }

  isOpsInspectionsApiAgainstLocalhost() {
    return DimensionerAppConfigurationService.IS_USE_OPS_INSPECTIONS_API_ON_LOCALHOST;
  }
}
