
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoBusyLoaderModule } from '@xpo-ltl/ngx-ltl-core/busy-loader';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCheckboxModule } from '@xpo-ltl/ngx-ltl-core/checkbox';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { XpoTimePickerModule } from '@xpo-ltl/ngx-ltl-core/time-picker';
import { XpoLtlPopoverModule } from '@xpo-ltl/ngx-ltl-core/xpo-ltl-popover';

const xpoCompoents = [
  XpoBusyLoaderModule,
  XpoLtlPopoverModule,
  XpoButtonModule,
  XpoCheckboxModule,
  XpoDialogModule,
  XpoIconModule,
  XpoSnackBarModule,
  XpoStatusIndicatorModule,
  XpoTimePickerModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...xpoCompoents,
  ],
  exports: [
    CommonModule,
    ...xpoCompoents
  ]
})
export class XpoComponentsModule { }
