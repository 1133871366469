import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, catchError, map, Observable, of, take } from 'rxjs';
import { CubiscanConstants } from '../../lib/classes/cubiscan-constants';
import { DimsCubiscanFormGroupItem } from '../../lib/enums/dims-cubiscan-form-group-item.enum';
import { DimensionerApiWrapperService } from '../../lib/services/dimensioner-api-wrapper.service';
import { CubiscanDeviceApiService } from '../../lib/services/cubiscan-device-api.service';
import { CubiscanDimensionerDevice } from '../../lib/classes/cubiscan-dimensioner-device';
import { CubiscanDimensioningOperation } from '../../lib/classes/cubiscan-dimensioning-operation';
import { ApiErrorHandlerService } from '../../lib/services/api-error-handler.service';
import { DevicesService } from '../../lib/services/devices.service';
import { UserNotificationService } from '../../lib/services/user-notification.service';
import { DimensionsComponentsBase } from '../base/dimensions-components-base';
import {
  ExtendedCubiscanDeviceScanningResponseDto,
} from '../../lib/classes/api/cubiscan/extended-cubiscan-device-scanning-response-dto';
import { List } from 'immutable';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LogItem } from '../../lib/classes/log-item';
import { DimensionerDataService } from '../../lib/services/dimensioner-data.service';
import { DimensionerAppConfigurationService } from '../../lib/services/dimensioner-app-configuration.service';
import { MiscUtil } from '../../lib/ui-entity-mat-table/utils/misc-util';

@Component({
  selector: 'app-dims-cubiscan-dashboard',
  templateUrl: './dims-cubiscan-dashboard.component.html',
  styleUrls: ['./dims-cubiscan-dashboard.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimsCubiscanDashboardComponent extends DimensionsComponentsBase implements OnInit {
  readonly formNames = DimsCubiscanFormGroupItem;
  proNbrInvalidFormat: boolean = false;

  protected isDimensionerActionEnabledBs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isDimensionerActionEnabled$: Observable<boolean> = this.isDimensionerActionEnabledBs$.asObservable();
  logsContent$: Observable<List<LogItem>>;//from dataService

  image1Src?: string; // 'data:image/jpg;base64,' + toReturnImage.base64string
  image2Src?: string; // 'data:image/jpg;base64,' + toReturnImage.base64string

  constructor(
    private fb: FormBuilder,
    private dimensionerApiWrapperService: DimensionerApiWrapperService,
    private cubiscanDeviceApiService: CubiscanDeviceApiService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    protected override devicesService: DevicesService,
    private userNotificationService: UserNotificationService,
    private dimensionerDataService: DimensionerDataService,
    private _sanitizer: DomSanitizer,
    protected dimensionerAppConfigurationService: DimensionerAppConfigurationService,
  ) {
    super(devicesService);
    this.logsContent$ = this.dimensionerDataService.logsContent$;
  }

  ngOnInit(): void {
    this.createFormGroup();
  }


  private createFormGroup() {
    this.filterFormGroup = this.fb.group({
      [DimsCubiscanFormGroupItem.DEVICE_SELECT]: this.fb.control(this.devicesService.getDefaultSelectedDeviceValue()),
      [DimsCubiscanFormGroupItem.PRO_NBR]: this.fb.control('01870119586'),
      [DimsCubiscanFormGroupItem.MANUAL_LENGTH]: this.fb.control('60', {
        validators: [Validators.required, Validators.min(1), Validators.max(CubiscanConstants.MANUAL_LENGTH_MAX), Validators.pattern('[0-9]*$')],
        updateOn: 'change',
      }),
      [DimsCubiscanFormGroupItem.MANUAL_WIDTH]: this.fb.control('20', {
        validators: [Validators.required, Validators.min(1), Validators.max(CubiscanConstants.MANUAL_WIDTH_MAX), Validators.pattern('[0-9]*$')],
        updateOn: 'change',
      }),
      [DimsCubiscanFormGroupItem.MANUAL_HEIGHT]: this.fb.control('20', {
        validators: [Validators.required, Validators.min(1), Validators.max(CubiscanConstants.MANUAL_HEIGHT_MAX), Validators.pattern('[0-9]*$')],
        updateOn: 'change',
      }),
      [DimsCubiscanFormGroupItem.COMMENT]: this.fb.control('Dougie is up'),
    });

  }


  getDimensionOperationFromForm(): CubiscanDimensioningOperation {
    let result: CubiscanDimensioningOperation;
    const lProNumberTxt: string = this.filterFormGroup.get(DimsCubiscanFormGroupItem.PRO_NBR).value;
    let lDeviceSerial: string = this.filterFormGroup.get(DimsCubiscanFormGroupItem.DEVICE_SELECT).value;
    const lManualLength: string = this.filterFormGroup.get(DimsCubiscanFormGroupItem.MANUAL_LENGTH).value;
    const lManualWidth: string = this.filterFormGroup.get(DimsCubiscanFormGroupItem.MANUAL_WIDTH).value;
    const lManualHeight: string = this.filterFormGroup.get(DimsCubiscanFormGroupItem.MANUAL_HEIGHT).value;
    const lComment: string = this.filterFormGroup.get(DimsCubiscanFormGroupItem.COMMENT).value;
    // console.log(
    //   `getDimensionOperationFromForm->${lDeviceSerial} ${lProNumberTxt} ${lManualLength} ${lManualWidth} ${lManualHeight}`,
    // );
    const lDevice: CubiscanDimensionerDevice = this.devicesService.getDeviceFromSerial(lDeviceSerial);
    result = new CubiscanDimensioningOperation(
      lDevice,
      lProNumberTxt,
      +lManualLength,
      +lManualWidth,
      +lManualHeight,
      lComment,
    );
    result.setScannedDimensions(44, 55, 66);
    return result;
  }

  onResetFormClicked() {
    this.filterFormGroup.reset({
      deviceSelect: this.filterFormGroup.get(DimsCubiscanFormGroupItem.DEVICE_SELECT).value,
    });
  }


  compareDevicesSelectFunction(o1: any, o2: any) {
    return o1.name === o2.name && o1.id === o2.id;
  }

  onScanOnlyClicked(isSimulatorTargetted: boolean = false) {
    const dimensionOperationFromForm: CubiscanDimensioningOperation = this.getDimensionOperationFromForm();
    dimensionOperationFromForm.cubiscanDevice.eventsContent$.subscribe((aEvntList: List<string>) => {
      this.setCurrentStatusString(aEvntList.last());
    });
    this.isDimensionerActionEnabledBs$.next(false);
    dimensionOperationFromForm.cubiscanDevice.getDimensions$(this.cubiscanDeviceApiService,
      dimensionOperationFromForm.cubiscanDevice, dimensionOperationFromForm.proNumber, isSimulatorTargetted)
      .pipe(catchError((error) => {
        this.dimensionerDataService.addLog('scan only error for device ' + dimensionOperationFromForm.cubiscanDevice.serialNumber + ':' + error);
        this.isDimensionerActionEnabledBs$.next(true);
        throw error;
      }))
      .subscribe((aResp: ExtendedCubiscanDeviceScanningResponseDto) => {
        dimensionOperationFromForm.setScannedDimensions(aResp.measurement.net_length, aResp.measurement.net_width, aResp.measurement.net_height);

        const lLog: string = 'DimensionOperation serial:' + dimensionOperationFromForm.cubiscanDevice.serialNumber + ' finished:' + aResp.toDimensionsString()
          + ' diff (manual/dimensioner):' + dimensionOperationFromForm.toDiffString();
        this.dimensionerDataService.addLog(lLog,
          MiscUtil.getBase64ImageSafeResourceUrl(aResp.measurement.image_file_1, this._sanitizer),
          MiscUtil.getBase64ImageSafeResourceUrl(aResp.measurement.image_file_2, this._sanitizer),
          MiscUtil.getBase64ImageSafeResourceUrl(aResp.measurement.image_file_ovv, this._sanitizer));
        const lNotifMsg: string = 'Difference between manual/dimensioner ' + dimensionOperationFromForm.toDiffString();
        this.isDimensionerActionEnabledBs$.next(true);
        this.userNotificationService.success(lNotifMsg);
      });
  }

  onCreateDimensionsClicked(isSimulatorTargetted: boolean = false) {
    const dimensionOperationFromForm: CubiscanDimensioningOperation = this.getDimensionOperationFromForm();
    // this.addLog(dimensionOperationFromForm.toLog());
    this.setCurrentStatusString('Calling dimensioner and then wait for data...');
    this.dimensionerDataService.addLog('starting create dimensions ------------------------>');
    this.isDimensionerActionEnabledBs$.next(false);
    //cubiscan scanning
    dimensionOperationFromForm.cubiscanDevice.getDimensions$(this.cubiscanDeviceApiService,
      dimensionOperationFromForm.cubiscanDevice, dimensionOperationFromForm.proNumber, isSimulatorTargetted)
      .pipe(catchError((error) => {
        console.log('onCsMeasureDataClicked error->', error);
        this.dimensionerDataService.addLog('onCsMeasureDataClicked error->' + error?.message);
        this.isDimensionerActionEnabledBs$.next(true);
        throw error;
      }))
      .subscribe((aResp: ExtendedCubiscanDeviceScanningResponseDto) => {
        //scanned
        dimensionOperationFromForm.setScannedDimensions(aResp.measurement.net_length, aResp.measurement.net_width, aResp.measurement.net_height);
        dimensionOperationFromForm.setImages(aResp.measurement.image_file_1, aResp.measurement.image_file_2, aResp.measurement.image_file_ovv);

        const lLog: string = 'DimensionOperation serial:' + dimensionOperationFromForm.cubiscanDevice.serialNumber
          + ' finished cubiscan:' + aResp.toDimensionsString()
          + ' diff (manual/dimensioner) ' + dimensionOperationFromForm.toDiffString();
        this.dimensionerDataService.addLog(lLog,
          MiscUtil.getBase64ImageSafeResourceUrl(aResp.measurement.image_file_1, this._sanitizer),
          MiscUtil.getBase64ImageSafeResourceUrl(aResp.measurement.image_file_2, this._sanitizer),
          MiscUtil.getBase64ImageSafeResourceUrl(aResp.measurement.image_file_ovv, this._sanitizer));
        const lNotifMsg: string = 'Difference between manual/dimensioner ' + dimensionOperationFromForm.toDiffString();
        this.userNotificationService.success(lNotifMsg);
        //send to API for createDimensions DB storage
        this.setCurrentStatusString('Calling create dimensions API...');
        this.dimensionerApiWrapperService.createDimensions$(dimensionOperationFromForm).pipe(
          take(1),
          map((response) => {
            console.log('createDimensions response->', response);
            const lLogString: string = 'createDimensions serial:' + dimensionOperationFromForm.cubiscanDevice.serialNumber
              + ' API response:' + JSON.stringify(response);
            this.dimensionerDataService.addLog(lLogString);
            // this.userNotificationService.success('Dimensions created', UserNotificationService.SHORT_DURATION);
            this.setCurrentStatusString('create dimensions API success ' + lNotifMsg);
            this.isDimensionerActionEnabledBs$.next(true);
            return response;
          }),
          catchError((err: Error) => {
            const lLogString: string = 'createDimensions serial:' + dimensionOperationFromForm.cubiscanDevice.serialNumber
              + 'API response error:' + JSON.stringify(err);
            this.apiErrorHandlerService.handleError(err);
            this.dimensionerDataService.addLog(lLogString);
            this.setCurrentStatusString('create dimensions API error');
            this.isDimensionerActionEnabledBs$.next(true);
            return of(err);
          }),
        ).subscribe();
      });
  }

  onCsMeasureDataClicked() {
    const dimensionOperationFromForm: CubiscanDimensioningOperation = this.getDimensionOperationFromForm();
    // this.addLog(dimensionOperationFromForm.toLog());
    this.setCurrentStatusString('Calling dimensioner...');
    this.isDimensionerActionEnabledBs$.next(false);
    console.debug('onCsMeasureDataClicked->');
    //cubiscan scanning
    dimensionOperationFromForm.cubiscanDevice.afterStartDimensioning$(this.cubiscanDeviceApiService,
      dimensionOperationFromForm.cubiscanDevice, dimensionOperationFromForm.proNumber, false)
      .pipe(catchError((error) => {
        console.log('onCsMeasureDataClicked error->', error);
        this.dimensionerDataService.addLog('onCsMeasureDataClicked error->' + error?.message);
        this.isDimensionerActionEnabledBs$.next(true);
        throw error;
      }))
      .subscribe((aResp: any) => {
        console.log('onCsMeasureDataClicked subscribe next->', aResp);
        this.dimensionerDataService.addLog('onCsMeasureDataClicked next json response->' + JSON.stringify(aResp),
          MiscUtil.getBase64ImageSafeResourceUrl(aResp.image_file_1, this._sanitizer),
          MiscUtil.getBase64ImageSafeResourceUrl(aResp.image_file_2, this._sanitizer));
        this.isDimensionerActionEnabledBs$.next(true);
      });
  }

  onCsMeasureClicked() {
    const dimensionOperationFromForm: CubiscanDimensioningOperation = this.getDimensionOperationFromForm();
    // this.addLog(dimensionOperationFromForm.toLog());
    this.setCurrentStatusString('Calling csMeasureData...');
    this.isDimensionerActionEnabledBs$.next(false);
    console.debug('onCsMeasureDataClicked->');
    //cubiscan scanning
    dimensionOperationFromForm.cubiscanDevice.startDimensioning$(this.cubiscanDeviceApiService,
      dimensionOperationFromForm.cubiscanDevice, dimensionOperationFromForm.proNumber, false)
      .pipe(catchError((error) => {
        console.log('onCsMeasureDataClicked error->', error);
        this.dimensionerDataService.addLog('onCsMeasureDataClicked error->' + JSON.stringify(error));
        this.isDimensionerActionEnabledBs$.next(true);
        throw error;
      }))
      .subscribe((aResp: any) => {
        console.log('onCsMeasureDataClicked subscribe next->', aResp);
        this.dimensionerDataService.addLog('onCsMeasureDataClicked next json response->' + JSON.stringify(aResp));
        this.isDimensionerActionEnabledBs$.next(true);
      });
  }

  onClearLogsClicked() {
    this.dimensionerDataService.clearLogs();
  }

  onImageClicked(aEvent: MouseEvent) {
    MiscUtil.toggleImageHeight(aEvent)
  }
}
